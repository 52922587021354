import { CircularProgress, TableCell, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { formatMoney } from '@trmediaab/zebra-utils';
import { format, getISOWeek } from 'date-fns';
import { sv } from 'date-fns/locale';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { sites } from 'trhub-utils';

import ErrorMessage from '~/components/ErrorMessage';
import TableList from '~/components/TableList';
import Time from '~/components/Time';

const useStyles = makeStyles(() => ({
  tableRowSum: {
    background: 'linear-gradient(45deg, #2196F3 5%, #21CBF3 30%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
  },
}));

const TransformDate = ({ dateType, date }) => {
  switch (dateType) {
    case 'weekly': {
      return (
        <>
          v
          {getISOWeek(new Date(date), {
            weekStartsOn: 1,
            firstWeekContainsDate: 4,
          })}
        </>
      );
    }
    case 'monthly': {
      const month = format(new Date(date), 'LLLL', { locale: sv });
      return <>{month.charAt(0).toUpperCase() + month.slice(1)}</>;
    }
    case 'yearly': {
      return <>{new Date(date).getFullYear()}</>;
    }
    default:
    // Do nothing
  }

  return <Time date={new Date(date)} />;
};

export default function SalesListResults({
  params,
  data,
  error,
  loading,
  page,
  pageSize,
  updatePage,
  updateRowSize,
}) {
  const classes = useStyles();

  const res = data?.getSalesReports;

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!res || loading) {
    return <CircularProgress />;
  }

  const headers = [
    'Datum',
    res.type === 'product' ? 'Produkt' : 'Projekt',
    'Antal beställningar',
    '6% Moms',
    '25% Moms',
    'Moms totalt',
    'Totalt ex moms',
    'Totalt inkl moms',
  ];

  return (
    <>
      <TableList
        page={page}
        pageSize={pageSize}
        data={res}
        headers={headers}
        updatePage={updatePage}
        updateRowSize={updateRowSize}
      >
        {res?.reports.length > 0 ? (
          res.reports.map((row, index) => (
            <Fragment key={index}>
              <TableRow key={index}>
                <TableCell
                  className={classes.tableCellDate}
                  rowSpan={
                    ['MC-EIN', 'MC-TVI'].includes(row.product?.sku) ? 2 : 1
                  }
                >
                  <TransformDate dateType={params.dateType} date={row.date} />
                </TableCell>
                <TableCell>
                  {['MC-EIN', 'MC-TVI'].includes(row.product?.sku)}
                  {res.type === 'product' ? row.product.name : sites[row.site]}
                </TableCell>
                <TableCell>{row.total.totalOrders}</TableCell>
                <TableCell>{formatMoney(row.total.totalVatSixPct)}</TableCell>
                <TableCell>
                  {formatMoney(row.total.totalVatTwentyFivePct)}
                </TableCell>
                <TableCell>{formatMoney(row.total.totalVat)}</TableCell>
                <TableCell>
                  {formatMoney(row.total.totalIncomeExcludingVat)}
                </TableCell>
                <TableCell>
                  {formatMoney(row.total.totalIncomeIncludingVat)}
                </TableCell>
              </TableRow>
              {['MC-EIN', 'MC-TVI'].includes(row.product?.sku) && (
                <TableRow key={index}>
                  <TableCell>Frakt</TableCell>
                  <TableCell>{row.total.shippingOrders}</TableCell>
                  <TableCell>
                    {formatMoney(row.total.shippingVatSixPct)}
                  </TableCell>
                  <TableCell>
                    {formatMoney(row.total.shippingVatTwentyFivePct)}
                  </TableCell>
                  <TableCell>{formatMoney(row.total.shippingVat)}</TableCell>
                  <TableCell>
                    {formatMoney(row.total.shippingIncomeExcludingVat)}
                  </TableCell>
                  <TableCell>
                    {formatMoney(row.total.shippingIncomeIncludingVat)}
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={10} align="center">
              Inga försäljningsstatistik finns för denna filtrering. Prova att
              ange en annan produkt/varumärke, eller ett annat datumintervall.
            </TableCell>
          </TableRow>
        )}
        {res?.reports.length > 0 && res.totalReport && (
          <TableRow className={classes.tableRowSum}>
            <TableCell
              rowSpan={res.totalReport.total.shippingOrders > 0 ? 2 : 1}
            >
              Total:
            </TableCell>
            <TableCell>
              {res.type === 'product' ? 'Produkt' : 'Projekt'}
            </TableCell>
            <TableCell>{res.totalReport.total.totalOrders}</TableCell>
            <TableCell>
              {formatMoney(res.totalReport.total.totalVatSixPct)}
            </TableCell>
            <TableCell>
              {formatMoney(res.totalReport.total.totalVatTwentyFivePct)}
            </TableCell>
            <TableCell>{formatMoney(res.totalReport.total.totalVat)}</TableCell>
            <TableCell>
              {formatMoney(res.totalReport.total.totalIncomeExcludingVat)}
            </TableCell>
            <TableCell>
              {formatMoney(res.totalReport.total.totalIncomeIncludingVat)}
            </TableCell>
          </TableRow>
        )}
        {res?.reports.length > 0 &&
          res.totalReport.total.shippingOrders > 0 && (
            <TableRow className={classes.tableRowSum}>
              <TableCell>Frakt</TableCell>
              <TableCell>{res.totalReport.total.shippingOrders}</TableCell>
              <TableCell>
                {formatMoney(res.totalReport.total.shippingVatSixPct)}
              </TableCell>
              <TableCell>
                {formatMoney(res.totalReport.total.shippingVatTwentyFivePct)}
              </TableCell>
              <TableCell>
                {formatMoney(res.totalReport.total.shippingVat)}
              </TableCell>
              <TableCell>
                {formatMoney(res.totalReport.total.shippingIncomeExcludingVat)}
              </TableCell>
              <TableCell>
                {formatMoney(res.totalReport.total.shippingIncomeIncludingVat)}
              </TableCell>
            </TableRow>
          )}
      </TableList>
    </>
  );
}

SalesListResults.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  data: PropTypes.object,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  updatePage: PropTypes.func.isRequired,
  updateRowSize: PropTypes.func.isRequired,
  params: PropTypes.shape({
    type: PropTypes.string.isRequired,
    dateType: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired,
    site: PropTypes.string.isRequired,
    betType: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }).isRequired,
};

TransformDate.propTypes = {
  date: PropTypes.string.isRequired,
  dateType: PropTypes.string.isRequired,
};
