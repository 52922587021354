import { Switch, useRouteMatch } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';

import DebtList from './DebtList';

export default function Debt() {
  const { path } = useRouteMatch();

  return (
    <>
      <Breadcrumb name="Prenskuld" />
      <Switch>
        <ErrorBoundRoute path={path} exact>
          <DebtList />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
