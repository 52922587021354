import { useQuery } from '@apollo/client';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import { giftStatus } from 'trhub-utils';

import useQueryParams from '~/hooks/useQueryParams';
import query from '~/utils/_GetProducts.gql';
import shallowEqual from '~/utils/shallowEqual';

import UnusedGiftCodesListResult from './UnusedGiftCodes/UnusedGiftCodesListResult';
import UsedGiftCodesListResult from './UsedGiftCodes/UsedGiftCodesListResult';

const useStyles = makeStyles(theme => ({
  textField: {
    maxWidth: 170,
    minWidth: 125,
    marginTop: 10,
  },
  autoComplete: {
    maxWidth: 300,
    minWidth: 260,
    marginTop: 10,
  },
  selectTextField: {
    maxWidth: 300,
    minWidth: 260,
    marginTop: 10,
  },
  box: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const initialValues = {
  from: format(new Date(), 'yyyy-MM'),
  to: format(new Date(), 'yyyy-MM'),
  productId: '',
  status: '',
};

export default function GiftCodesList() {
  const classes = useStyles();

  const { state, params, handleChange, clear } = useQueryParams(initialValues);

  const { error, data } = useQuery(query, {
    variables: { limit: 150, filter: { productType: 'subscription' } },
  });

  const productList = data?.list?.items ?? [];

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  if (!productList) {
    return <CircularProgress />;
  }

  return (
    <>
      <Box className={classes.box} display="flex">
        <Box
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          columnGap="10px"
          marginTop="-10px"
        >
          <TextField
            className={classes.textField}
            label="Från"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="month"
            value={state.from}
            onChange={e => handleChange('from', e.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Till"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="month"
            value={state.to}
            onChange={e => handleChange('to', e.target.value)}
          />
          <TextField
            className={classes.selectTextField}
            label="Produkt"
            variant="outlined"
            select
            value={state.productId}
            onChange={e => handleChange('productId', e.target.value)}
          >
            <MenuItem value={''}>
              <em>Alla</em>
            </MenuItem>
            {productList.map(product => (
              <MenuItem key={product.name} value={product.id}>
                {product.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className={classes.selectTextField}
            label="Status"
            variant="outlined"
            select
            value={state.status}
            onChange={e => handleChange('status', e.target.value)}
          >
            <MenuItem value={''}>
              <em>Alla</em>
            </MenuItem>
            {Object.keys(giftStatus).map(status => (
              <MenuItem key={status} value={status}>
                {giftStatus[status]}
              </MenuItem>
            ))}
          </TextField>
          {!shallowEqual(initialValues, state) && (
            <Button
              color="primary"
              sx={{
                textTransform: 'none',
              }}
              onClick={clear}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>
      </Box>

      <Box className={classes.box}>
        <UnusedGiftCodesListResult params={params} />
      </Box>
      <Box className={classes.box}>
        <UsedGiftCodesListResult params={params} />
      </Box>
    </>
  );
}
