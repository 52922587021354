import { Add } from '@mui/icons-material';
import { Box, Button, MenuItem, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link, useLocation } from 'react-router-dom';
import { issueStatus } from 'trhub-utils';

import shallowEqual from '~/utils/shallowEqual';
import toOptions from '~/utils/toOptions';

import useQueryParams from '../../hooks/useQueryParams';
import IssueListResult from './IssueListResult';

const useStyles = makeStyles({
  textField: {
    maxWidth: 150,
    minWidth: 125,
    marginTop: 10,
  },
});

const initialValues = {
  searchValues: '',
  assignee: '',
  status: 'OPEN',
};

export default function IssueList() {
  const classes = useStyles();

  const { pathname } = useLocation();

  const { state, params, handleChange, clear } = useQueryParams(initialValues);

  const options = {
    status: toOptions(issueStatus),
  };

  return (
    <>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Box
          maxWidth="100%"
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          columnGap="10px"
          marginTop="-10px"
        >
          <TextField
            className={classes.textField}
            label="Sök"
            variant="outlined"
            value={state.searchValues}
            onChange={e => handleChange('searchValues', e.target.value)}
          />

          <TextField
            className={classes.textField}
            label="Mottagare"
            variant="outlined"
            select
            value={state.assignee}
            onChange={e => handleChange('assignee', e.target.value)}
          >
            <MenuItem value="">
              <em>Alla</em>
            </MenuItem>
          </TextField>
          <TextField
            className={classes.textField}
            label="Status"
            variant="outlined"
            select
            value={state.status}
            onChange={e => handleChange('status', e.target.value)}
          >
            <MenuItem value="">
              <em>Alla</em>
            </MenuItem>
            {options.status.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>
          {!shallowEqual(initialValues, state) && (
            <Button
              color="primary"
              sx={{
                textTransform: 'none',
              }}
              onClick={clear}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>
        <Button
          component={Link}
          to={`${pathname}/add`}
          color="primary"
          variant="contained"
          startIcon={<Add />}
        >
          Lägg till
        </Button>
      </Box>
      <Box>
        <IssueListResult params={params} options={options} />
      </Box>
    </>
  );
}
