import { Chip, Link } from '@mui/material';
import { green, red, yellow } from '@mui/material/colors';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { orderBuilderStatus } from 'trhub-utils';

import DataGrid from '~/components/DataGrid';
import Time from '~/components/Time';

import query from './_GetOrderBuilders.gql';

const columns = [
  {
    field: 'status',
    flex: 1,
    headerName: 'Status',
    sortable: false,
    valueGetter: params => orderBuilderStatus[params.value],
    renderCell: params => {
      let color = 'default';
      let background = 'default';

      if (params.row.status === 'in_progress') {
        background = yellow[500];
        color = 'black';
      }

      if (params.row.status === 'complete') {
        background = green[500];
        color = 'white';
      }

      if (params.row.status === 'closed') {
        background = red[500];
        color = 'white';
      }

      return (
        <Chip
          label={params.value}
          size="small"
          css={{
            background: background,
            color: color,
          }}
        />
      );
    },
  },
  {
    field: 'id',
    flex: 4,
    headerName: 'Girokampanj',
    sortable: false,
    renderCell: params => (
      <Link component={RouterLink} to={`/order-builders/${params.value}`}>
        {params.row.name}
      </Link>
    ),
  },
  {
    field: 'created',
    flex: 2,
    headerName: 'Skapad',
    renderCell: params => (
      <Time date={new Date(params.value)} format="yyyy-MM-dd HH:mm" />
    ),
  },
  {
    field: 'sendDate',
    flex: 2,
    headerName: 'Utskickad',
    renderCell: params =>
      params.value && (
        <Time date={new Date(params.value)} format="yyyy-MM-dd HH:mm" />
      ),
  },
];

export default function OrderBuildersListResult({ params }) {
  const { search } = params;

  return (
    <DataGrid
      columns={columns}
      query={query}
      defaultSort="-created"
      filter={{
        search: search?.toString(),
      }}
    />
  );
}

OrderBuildersListResult.propTypes = {
  params: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};
