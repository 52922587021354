import {
  Autocomplete,
  Box,
  Button,
  LinearProgress,
  MenuItem,
  TextField as TextFieldCore,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import countries from 'i18n-iso-countries';
import Personnummer from 'personnummer';
import PropTypes from 'prop-types';
import { isValidOrgNo } from 'se-org-no';
import { userRoles } from 'trhub-utils';
import * as Yup from 'yup';

import toOptions from '~/utils/toOptions';

countries.registerLocale(require('i18n-iso-countries/langs/sv.json'));

function countryToFlag(isoCode) {
  return isoCode
    .toUpperCase()
    .replace(/./g, char => String.fromCodePoint(char.codePointAt(0) + 127397));
}

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

const validationSchema = Yup.object().shape({
  companyName: Yup.string().when('collectionPayee', {
    is: value => value,
    then: Yup.string().required('Samlingsbetalare måste ange företagsnamn.'),
  }),
  customerNumber: Yup.number().positive('Kundnummer får inte vara negativt.'),
  personalIdentityNumber: Yup.string()
    .notRequired()
    .length(12, 'Personnummer ska vara 12 siffror')
    .test('valid', 'Personnummer ej korrekt', value =>
      !value ? true : Personnummer.valid(value),
    ),
  organizationNumber: Yup.string().when('collectionPayee', {
    is: value => value,
    then: Yup.string()
      .required('Samlingsbetalare måste ange organisationssnummer.')
      .test(
        'valid',
        'Organisationsnumret är inte korrekt. Svenska organisationsnummer måste börja med "SE".',
        val => {
          if (!val) {
            // Yup validates parallell, so required might not have been tested yet.
            return true;
          }
          if (val.startsWith('SE')) {
            return isValidOrgNo(val.replace('SE', ''));
          }
          // Org no must start with letter
          return /[a-z]/i.test(val);
        },
      ),
  }),

  email: Yup.string().email('Ogiltig mejladress.'),
  mobile: Yup.string().matches(
    /^[\s()+-]*(\d[\s()+-]*){6,20}$/,
    'Ange ett korrekt mobilnummer',
  ),
  postalCode: Yup.string()
    .matches(/^\d+$/, 'Postnummer får enbart innehålla siffror')
    .test(
      'len',
      'Postnummer måste innehålla 4 eller 5 siffror',
      val => !val || val.length === 4 || val.length === 5,
    ),
});

export default function UserForm({ initialValues, onSubmit }) {
  const classes = useStyles();

  const countryOptions = Object.entries(
    countries.getNames('sv', { select: 'official' }),
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ submitForm, isSubmitting, values, setFieldValue }) => (
        <Form
          css={({ theme }) => ({
            '> * + *': {
              marginTop: theme.spacing(2),
            },
            '> *': {
              width: '100%',
            },
            '> .MuiFormControl-root': {
              backgroundColor: theme.palette.background.default,
            },
          })}
        >
          <Field
            component={TextField}
            name="firstName"
            label="Förnamn"
            variant="outlined"
          />
          <Field
            component={TextField}
            name="lastName"
            label="Efternamn"
            variant="outlined"
          />
          <Field
            component={TextField}
            name="customerNumber"
            label="Kundnummer"
            type="number"
            value={values.customerNumber || ''}
            variant="outlined"
          />
          <Field
            component={TextField}
            name="personalIdentityNumber"
            label="Personnummer"
            value={values.personalIdentityNumber || ''}
            variant="outlined"
          />
          <Field
            component={CheckboxWithLabel}
            name="collectionPayee"
            Label={{ label: 'Samlingsbetalare' }}
            type="checkbox"
            checked={values.collectionPayee || false}
            value={values.collectionPayee || false}
            variant="outlined"
          />
          <Field
            component={TextField}
            name="companyName"
            label="Företagsnamn"
            variant="outlined"
          />
          <Field
            component={TextField}
            name="organizationNumber"
            label="Organisationsnummer"
            value={values.organizationNumber || ''}
            variant="outlined"
            helperText="Svenska organisationsnummer ska börja med SE"
          />
          <Field
            component={TextField}
            name="email"
            label="Email"
            type="email"
            value={values.email || ''}
            variant="outlined"
          />
          <Field
            component={TextField}
            name="mobile"
            label="Mobilnummer"
            variant="outlined"
          />
          <Field
            component={TextField}
            name="careOf"
            label="C/o"
            variant="outlined"
          />
          <Field
            component={TextField}
            name="address"
            label="Adress"
            variant="outlined"
          />
          <Field
            component={TextField}
            name="postalCode"
            label="Postnummer"
            variant="outlined"
          />
          <Field
            component={TextField}
            name="city"
            label="Stad"
            variant="outlined"
          />
          <Field
            component={Autocomplete}
            name="country"
            label="Land"
            variant="outlined"
            style={{ width: 300 }}
            options={countryOptions}
            classes={{
              option: classes.option,
            }}
            onChange={(_, value) => setFieldValue('country', value[0])}
            autoHighlight
            defaultValue={
              initialValues.country != null
                ? countryOptions.find(opt => opt[0] === initialValues.country)
                : undefined
            }
            isOptionEqualToValue={(option, value) => option[0] === value[0]}
            getOptionLabel={option => option[1]}
            renderOption={({ key, ...props }, option) => (
              <li key={key} {...props}>
                <span>{countryToFlag(option[0])}</span>
                {option[1]} ({option[0]})
              </li>
            )}
            renderInput={params => (
              <TextFieldCore
                {...params}
                label="Land"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
          <Field
            component={TextField}
            select
            name="distributor"
            label="Distributör"
            variant="outlined"
            helperText="Förvald distributör av Travronden papperstidning"
          >
            {toOptions(['MTD', 'PostNord']).map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Field>
          <Field
            component={TextField}
            select
            name="roles"
            label="Roller"
            variant="outlined"
            SelectProps={{
              multiple: true,
            }}
          >
            {toOptions(userRoles).map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Field>
          {isSubmitting && <LinearProgress />}
          <Box>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              Spara
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

UserForm.propTypes = {
  initialValues: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    customerNumber: PropTypes.number,
    personalIdentityNumber: PropTypes.string,
    organizationNumber: PropTypes.string,
    collectionPayee: PropTypes.bool,
    email: PropTypes.string,
    mobile: PropTypes.string,
    careOf: PropTypes.string,
    address: PropTypes.string,
    distributor: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
};
