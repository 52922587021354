import { useQuery } from '@apollo/client';
import { Delete as DeleteIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import PropTypes from 'prop-types';
import {
  allCostCenters,
  allProjects,
  betTypes,
  organizations,
  paymentIntervals,
  paymentMethods,
  productTypes,
  sites,
  VATs,
} from 'trhub-utils';
import * as Yup from 'yup';

import DateInputField from '~/components/DateInputField';
import ErrorMessage from '~/components/ErrorMessage';
import { NumberField } from '~/components/NumberField';
import TextEditor from '~/components/TextEditor';
import { productType } from '~/propTypes';
import query from '~/utils/_GetProducts.gql';
import toOptions from '~/utils/toOptions';

const validationSchema = Yup.object().shape({
  organization: Yup.string().required('En organisation måste anges.'),
  name: Yup.string().required('Ett namn måste anges.'),
  type: Yup.string().required('En produkttyp måste anges.'),
  distributable: Yup.boolean(),
  purchasable: Yup.boolean(),
  price: Yup.number()
    .required('Pris måste anges.')
    .moreThan(-1, 'Pris måste vara 0 eller ett positivt heltal.'),
  vat: Yup.number()
    .moreThan(-1, 'Moms måste vara 0 eller ett positivt heltal.')
    .required('Moms måste anges.')
    .integer('Moms måste vara ett heltal.'),
  betType: Yup.array().when('type', {
    is: value => value === 'bettip' || value === 'ticket',
    then: Yup.array()
      .min(1, 'Minst en spelform måste anges.')
      .required('Minst en spelform måste anges.'),
  }),
  roundDate: Yup.date().when('type', {
    is: value => value === 'bettip',
    then: Yup.date('Ogiltigt datum.')
      .min(format(new Date(), 'yyyy-MM-dd'), 'Ogiltigt datum.')
      .nullable(),
  }),
  roundId: Yup.string().when('type', {
    is: value => value === 'bettip',
    then: Yup.string().required('Omgångs-ID måste anges.'),
  }),
  paymentInterval: Yup.string().when('type', {
    is: value => value === 'subscription',
    then: Yup.string()
      .required('Ett betalningsintervall måste anges.')
      .test(
        'notMonthlyNotification',
        'Månadsintervall får inte anges för aviseringar.',
        function (value) {
          if (
            value === '1' &&
            this.parent.allowedPaymentMethods.includes('notification')
          ) {
            return false;
          }
          return true;
        },
      ),
  }),
  site: Yup.string().required('En sajt måste anges.'),
  punches: Yup.number().when('type', {
    is: value => value === 'ticket',
    then: Yup.number()
      .moreThan(-1, 'Klippkort måste vara 0 eller ett positivt heltal.')
      .required('Klippkort måste anges.')
      .integer('Klippkort måste vara ett heltal.'),
  }),
  costCenter: Yup.string().when('type', {
    is: value => value === 'subscription',
    then: Yup.string().required('Ett kostnadsställe måste anges.'),
  }),
  project: Yup.string().when('type', {
    is: value => value === 'subscription',
    then: Yup.string().required('Ett projekt måste anges.'),
  }),
});

const skuValidation = Yup.object().shape({
  sku: Yup.string().required('SKU måste anges.'),
});

const PresentationSection = ({ title, name, values, setFieldValue }) => (
  <Paper
    sx={theme => ({
      padding: theme.spacing(3),
      '> * + *': {
        marginTop: theme.spacing(2),
      },
    })}
  >
    <Typography variant="h6" component="h2" gutterBottom>
      {title}
    </Typography>
    {name === 'presentation' && (
      <Field
        component={CheckboxWithLabel}
        name={`promoted`}
        variant="outlined"
        Label={{ label: 'Visa på prenumerationssidan' }}
        checked={values?.promoted || false}
        value={values?.promoted || false}
        type="checkbox"
      />
    )}
    <Field
      component={TextField}
      name={`${name}.headline`}
      label="Rubrik"
      variant="outlined"
    />
    <Box>
      <Typography component="h3">Infotext</Typography>
      <TextEditor
        value={`${values[name]?.infoText}` ?? ''}
        menuVariant="reduced"
        height={300}
        onChange={({ text }) => setFieldValue(`${name}.infoText`, text)}
      />
    </Box>
    <Field
      component={TextField}
      name={`${name}.buttonText`}
      label="Knapptext"
      variant="outlined"
    />
    <Field
      component={TextField}
      name={`${name}.paymentTerms`}
      label="Text betalningsvillkor"
      variant="outlined"
    />
    {name === 'presentation' && (
      <>
        <Typography variant="subtitle1" component="h3" gutterBottom>
          Punktlista
        </Typography>
        {values[name].bullets.map((bullet, i, array) => (
          <Grid container key={i}>
            <Grid item xs={11}>
              <Field
                component={TextField}
                name={`${name}.bullets.${i}.headline`}
                label="Rubrik"
                variant="outlined"
              />
              <Field
                component={TextField}
                name={`${name}.bullets.${i}.text`}
                label="Text"
                variant="outlined"
                css={
                  i < array.length - 1
                    ? ({ theme }) => ({
                        marginTop: theme.spacing(1),
                        paddingBottom: theme.spacing(2),
                        borderBottom: 'solid 1px',
                        borderBottomColor: theme.palette.divider,
                      })
                    : ({ theme }) => ({
                        marginTop: theme.spacing(1),
                        paddingBottom: theme.spacing(2),
                      })
                }
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                aria-label="delete"
                onClick={() => {
                  setFieldValue(
                    `${name}.bullets`,
                    values[name].bullets.filter((_, key) => key !== i),
                  );
                }}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Button
          variant="contained"
          onClick={() => {
            setFieldValue(`${name}.bullets`, [
              ...values[name].bullets,
              {
                headline: '',
                text: '',
              },
            ]);
          }}
        >
          + Lägg till
        </Button>
      </>
    )}
  </Paper>
);

PresentationSection.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default function ProductForm({
  initialValues,
  onSubmit,
  addProduct = false,
}) {
  const { error, data } = useQuery(query, {
    variables: { filter: { productType: 'subscription' } },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }

  const productList = data?.list?.items ?? [];

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={
        addProduct
          ? // eslint-disable-next-line unicorn/prefer-spread
            validationSchema.concat(skuValidation)
          : validationSchema
      }
      validateOnBlur={false}
    >
      {({ submitForm, isSubmitting, values, setFieldValue }) => (
        <Form
          css={{
            '.MuiFormControl-root': {
              width: '100%',
            },
          }}
        >
          <Grid container spacing={3}>
            <Grid item sm={6}>
              <Paper
                sx={theme => ({
                  padding: theme.spacing(3),
                  '> * + *': {
                    marginTop: theme.spacing(2),
                  },
                })}
              >
                <Typography variant="h6" component="h2" gutterBottom>
                  Produktinfo
                </Typography>
                <Field
                  label="Organisation"
                  component={TextField}
                  select
                  name="organization"
                  variant="outlined"
                >
                  {toOptions(organizations).map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.text}
                    </MenuItem>
                  ))}
                </Field>
                <Field
                  component={TextField}
                  name="name"
                  label="Namn"
                  variant="outlined"
                />
                <Field
                  component={TextField}
                  name="sku"
                  label="SKU"
                  variant="outlined"
                  disabled={!addProduct}
                />
                <Field
                  label="Produkttyp"
                  component={TextField}
                  select
                  name="type"
                  variant="outlined"
                >
                  {toOptions(productTypes).map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.text}
                    </MenuItem>
                  ))}
                </Field>
                <Field
                  component={CheckboxWithLabel}
                  name="purchasable"
                  Label={{ label: 'Köpbar' }}
                  type="checkbox"
                  checked={values.purchasable || false}
                  value={values.purchasable || false}
                  variant="outlined"
                />
                {values.type === 'subscription' && (
                  <>
                    <Field
                      component={CheckboxWithLabel}
                      name="distributable"
                      Label={{ label: 'Distribueringsbar (KRAV för Billecta)' }}
                      type="checkbox"
                      checked={values.distributable || false}
                      value={values.distributable || false}
                      variant="outlined"
                    />
                    <Field
                      component={TextField}
                      select
                      name="paymentInterval"
                      label="Betalningsinterval"
                      variant="outlined"
                    >
                      {toOptions(paymentIntervals).map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.text}
                        </MenuItem>
                      ))}
                    </Field>
                    <Field
                      component={TextField}
                      select
                      name="products"
                      label="Produkter"
                      variant="outlined"
                      SelectProps={{
                        multiple: true,
                      }}
                    >
                      {productList.map(product => (
                        <MenuItem key={product.id} value={product.id}>
                          {product.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </>
                )}
                {values.type === 'bettip' && (
                  <>
                    <DateInputField
                      name="roundDate"
                      label="Omgångsdatum"
                      clearIconEnabled={values.roundDate !== ''}
                      setFieldValue={setFieldValue}
                      value={values.roundDate}
                    />
                    <Field
                      component={TextField}
                      name="roundId"
                      label="Omgångs-ID"
                      variant="outlined"
                    />
                  </>
                )}

                <NumberField name="price" label="Pris" value={values.price} />
                <NumberField select name="vat" label="Moms" value={values.vat}>
                  {VATs.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </NumberField>
                <Field
                  component={TextField}
                  select
                  name="site"
                  label="Sajt"
                  variant="outlined"
                >
                  {toOptions(sites).map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.text}
                    </MenuItem>
                  ))}
                </Field>
                <Field
                  component={TextField}
                  select
                  name="allowedPaymentMethods"
                  label="Betalningsalternativ"
                  variant="outlined"
                  SelectProps={{
                    multiple: true,
                  }}
                >
                  {toOptions(paymentMethods)
                    .filter(option => option.value !== 'giftCode')
                    .map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.text}
                      </MenuItem>
                    ))}
                </Field>
                <Field
                  component={CheckboxWithLabel}
                  name="isGiftProduct"
                  Label={{ label: 'Gåvoprodukt' }}
                  type="checkbox"
                  checked={values.isGiftProduct || false}
                  value={values.isGiftProduct || false}
                  variant="outlined"
                />
                <Field
                  component={CheckboxWithLabel}
                  name="eligibleForCancellationOffer"
                  Label={{ label: 'Berättiga för uppsägningserbjudande' }}
                  type="checkbox"
                  checked={values.eligibleForCancellationOffer || false}
                  value={values.eligibleForCancellationOffer || false}
                  variant="outlined"
                />
                <Field
                  component={CheckboxWithLabel}
                  name="eligibleToPauseUponCancellation"
                  Label={{ label: 'Berättiga för paus vid uppsägning' }}
                  type="checkbox"
                  checked={values.eligibleToPauseUponCancellation || false}
                  value={values.eligibleToPauseUponCancellation || false}
                  variant="outlined"
                />
                {['bettip', 'ticket', 'subscription'].includes(values.type) && (
                  <Field
                    component={TextField}
                    select
                    name="betType"
                    label="Spelformer"
                    variant="outlined"
                    SelectProps={{
                      multiple: true,
                    }}
                  >
                    {betTypes.map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Field>
                )}
                {values.type === 'ticket' && (
                  <NumberField
                    name="punches"
                    label="Klipp"
                    value={values.punches}
                  />
                )}
                {values.type === 'subscription' && (
                  <>
                    <Field
                      component={TextField}
                      select
                      name="costCenter"
                      label="Kostnadsställe"
                      variant="outlined"
                    >
                      {allCostCenters.map(option => (
                        <MenuItem key={option} value={option.toString()}>
                          {option}
                        </MenuItem>
                      ))}
                    </Field>
                    <Field
                      component={TextField}
                      select
                      name="project"
                      label="Project"
                      variant="outlined"
                    >
                      {allProjects.map(option => (
                        <MenuItem key={option} value={option.toString()}>
                          {option}
                        </MenuItem>
                      ))}
                    </Field>
                  </>
                )}
              </Paper>

              <Box pt={3}>
                {isSubmitting && <LinearProgress />}
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  Spara
                </Button>
              </Box>
            </Grid>

            <Grid item sm={6}>
              <PresentationSection
                title="Utvisning"
                name="presentation"
                values={values}
                setFieldValue={setFieldValue}
              />
              {values.isGiftProduct && (
                <Box pt={3}>
                  <PresentationSection
                    title="Utvisning för inlösare av gåvoprenumeration"
                    name="redeemPresentation"
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

const productPropType = PropTypes.shape({
  ...productType,
  products: PropTypes.arrayOf(PropTypes.string),
});

ProductForm.propTypes = {
  initialValues: productPropType,
  onSubmit: PropTypes.func.isRequired,
  addProduct: PropTypes.bool,
};
