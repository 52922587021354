import { useQuery } from '@apollo/client';
import ReportIcon from '@mui/icons-material/Report';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Button,
  CircularProgress,
  Link,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Link as RouterLink, NavLink, useLocation } from 'react-router-dom';
import {
  complaintCodes,
  complaintCodesByDamaged,
  distributableProducts,
} from 'trhub-utils';

import ErrorMessage from '~/components/ErrorMessage';
import NoData from '~/components/NoData';
import TableList from '~/components/TableList';
import Time from '~/components/Time';
import usePagination from '~/hooks/usePagination';

import query from './_GetDistributionsByUser.gql';

export default function Distributions({ user }) {
  const { pathname } = useLocation();

  const { page, pageSize, updatePage, updateRowSize } = usePagination(10);

  const { error, loading, data } = useQuery(query, {
    variables: { user, page: page, limit: pageSize },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (loading) {
    return <CircularProgress />;
  }

  const headers = ['Utgåva', 'Papperstidning', 'Utskicksdatum', 'Distributör'];

  const res = data?.getDistributionsByUser;

  return (
    <TableList
      title="Utskick"
      page={page}
      pageSize={pageSize}
      data={res}
      headers={headers}
      updatePage={updatePage}
      updateRowSize={updateRowSize}
    >
      {res?.distributions.length > 0 ? (
        res.distributions.map(distribution => (
          <TableRow key={distribution.id}>
            <TableCell>
              <Link
                component={RouterLink}
                to={`/editions/${distribution.edition.id}`}
              >
                {distribution.edition.editionLabel}
              </Link>
            </TableCell>
            <TableCell>
              {distributableProducts[distribution.edition.sku]}
            </TableCell>
            <TableCell>
              <Time date={new Date(distribution.edition.deliveryDate)} />
            </TableCell>
            <TableCell>{distribution.distributor}</TableCell>
            {distribution.edition.sku === 'MC-TRT' &&
              !distribution.complaint.reason && (
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    component={NavLink}
                    to={`${pathname}/complaint/${distribution.id}`}
                  >
                    Reklamera
                  </Button>
                </TableCell>
              )}
            {distribution.complaint.reason && (
              <TableCell>
                <ReportIcon style={{ verticalAlign: 'middle' }} />
                {distribution.complaint.reason > 20
                  ? `${complaintCodes[2]} (${
                      complaintCodesByDamaged[distribution.complaint.reason]
                    })`
                  : complaintCodes[distribution.complaint.reason]}
              </TableCell>
            )}
            {distribution.error && (
              <TableCell>
                <Tooltip title={distribution.error}>
                  <WarningIcon />
                </Tooltip>
              </TableCell>
            )}
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={headers.length}>
            <NoData text="Den här användaren har inte mottagit några utgåvor" />
          </TableCell>
        </TableRow>
      )}
    </TableList>
  );
}

Distributions.propTypes = {
  user: PropTypes.string.isRequired,
};
