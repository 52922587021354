import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import * as React from 'react';

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_CLIENT_KEY,
  appVersion: process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA?.slice(0, 7),
  releaseStage: process.env.REACT_APP_VERCEL_ENV,
  enabledReleaseStages: ['production'],
  maxBreadcrumbs: 30,
  plugins: [new BugsnagPluginReact(React)],
});

export { default } from '@bugsnag/js';
