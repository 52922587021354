import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { formatMoney } from '@trmediaab/zebra-utils';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import { Link as RouterLink, NavLink } from 'react-router-dom';
import { Types } from 'trhub-utils';

import ErrorMessage from '~/components/ErrorMessage';
import TableList from '~/components/TableList';
import Time from '~/components/Time';
import usePagination from '~/hooks/usePagination';

import approveDeviationMutation from '../../Order/_ApproveDeviation.gql';
import clearDeviationMutation from '../../Order/_ClearDeviation.gql';
import sendDeviationReminderMutation from '../../Order/_SendDeviationReminder.gql';
import query from './_GetDeviatedOrders.gql';

const useStyles = makeStyles(() => ({
  tableRowSum: {
    background: 'linear-gradient(45deg, #2196F3 5%, #21CBF3 30%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
  },
}));

function ConfirmationDialog({ open, title, message, onCancel, onConfirm }) {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="confirmation-dialog-title"
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onCancel}>
          Avbryt
        </Button>
        <Button variant="contained" color="secondary" onClick={onConfirm}>
          Ja
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default function DeviationsListResults({ params }) {
  const {
    from,
    to,
    source,
    organization,
    deviationResolved,
    deviationStatus,
    handledFrom,
    handledTo,
  } = params;

  const apollo = useApolloClient();

  const classes = useStyles();

  const { page, pageSize, updatePage, updateRowSize } = usePagination(15);
  const { error, data } = useQuery(query, {
    variables: {
      page: page,
      limit: pageSize,
      filter: {
        from,
        to,
        source,
        organization,
        deviationResolved,
        deviationStatus,
        handledFrom,
        handledTo,
      },
    },
  });
  const res = data?.list;

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [confirmationAction, setConfirmationAction] = useState(null);
  const [cancelAction, setCancelAction] = useState(null);

  const handleConfirmationOpen = (
    title,
    message,
    confirmationCallback,
    cancelCallback,
  ) => {
    setConfirmationOpen(true);
    setConfirmationTitle(title);
    setConfirmationMessage(message);
    setConfirmationAction(() => confirmationCallback);
    setCancelAction(() => cancelCallback);
  };

  const handleConfirmationCancel = () => {
    setConfirmationOpen(false);
  };

  const useMutationWithConfirmation = (mutation, setConfirmationOpen) => {
    const [action] = useMutation(mutation, {
      onCompleted: async () => {
        // Ensure all lists are refreshed
        await apollo.resetStore();
      },
    });

    const runMutationWithConfirmation = async (orderId, input) => {
      try {
        await action({
          variables: {
            id: orderId,
            input: input,
          },
        });
      } catch (error) {
        console.error('Error executing mutation:', error);
      }

      // Close the confirmation box
      setConfirmationOpen(false);
    };

    return runMutationWithConfirmation;
  };

  const runApproveDeviationMutation = useMutationWithConfirmation(
    approveDeviationMutation,
    setConfirmationOpen,
  );

  const runClearDeviationMutation = useMutationWithConfirmation(
    clearDeviationMutation,
    setConfirmationOpen,
  );

  const runSendDeviationReminderMutation = useMutationWithConfirmation(
    sendDeviationReminderMutation,
    setConfirmationOpen,
  );

  const startSubscription = async orderId => {
    const input = {
      deviationInfo: { status: 'unresolved_subscription_started' },
    };

    await runApproveDeviationMutation(orderId, input);
  };

  const sendReminder = async orderId => {
    const input = {
      deviationInfo: { status: 'unresolved_reminder_sent' },
    };

    await runSendDeviationReminderMutation(orderId, input);
  };

  const cancelDeviation = async orderId => {
    const input = {
      deviationInfo: { status: 'resolved_cancelled' },
    };

    await runClearDeviationMutation(orderId, input);
  };

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!res) {
    return <CircularProgress />;
  }

  const baseHeaders = [
    'Order',
    'Betalstatus',
    'Orderbelopp',
    'Inbetalt',
    'Differens',
    'Kund',
    'Källa',
    'Status',
  ];

  const headers = deviationResolved
    ? [
        'Hanteringsdatum',
        ...baseHeaders,
        'Differensbelopp',
        'Avvikelsedatum',
        'Hanterad av',
      ]
    : ['Avvikelsedatum', ...baseHeaders];

  const deviationType = deviationResolved ? 'Hanterade ' : 'Ohanterade ';

  return (
    <>
      <Box mb={3}>
        <Card>
          <CardContent>
            <Typography variant="h6" component="h2">
              {deviationType} Avvikelser (Totalt för perioden:{' '}
              {formatMoney(res.totalAmount)})
            </Typography>
          </CardContent>
          <TableList
            page={page}
            pageSize={pageSize}
            data={res}
            headers={headers}
            updatePage={updatePage}
            updateRowSize={updateRowSize}
          >
            {res?.items.length > 0 ? (
              res.items.map((item, index) => (
                <Fragment key={index}>
                  <TableRow hover key={index}>
                    <TableCell className={classes.tableCellDate}>
                      <Time
                        date={
                          deviationResolved
                            ? new Date(item.deviationInfo.clearance.date)
                            : new Date(
                                item.payments[item.payments.length - 1].date,
                              )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Link component={RouterLink} to={`/order/${item.id}`}>
                        {item.label}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {Types.paymentStatus[item.paymentStatus]}
                    </TableCell>
                    <TableCell>{formatMoney(item.totalPrice)}</TableCell>
                    <TableCell>{formatMoney(item.paidAmount)}</TableCell>
                    <TableCell>
                      {(item.paidAmount - item.totalPrice > 0 ? '+' : '') +
                        formatMoney(item.paidAmount - item.totalPrice)}
                    </TableCell>
                    <TableCell>
                      <Link
                        component={RouterLink}
                        to={`/users/${item.user.id}`}
                      >
                        {item.user.name}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {item.payments[item.payments.length - 1].source}
                    </TableCell>
                    <TableCell>
                      {Types.deviationStatus[item.deviationInfo.status]}
                    </TableCell>
                    {deviationResolved && (
                      <>
                        <TableCell>
                          {(item.deviationInfo.clearance.amount > 0
                            ? '+'
                            : '') +
                            formatMoney(item.deviationInfo.clearance.amount)}
                        </TableCell>
                        <TableCell>
                          <Time
                            date={
                              new Date(
                                item.payments[item.payments.length - 1].date,
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          {item.deviationInfo.clearance.userName}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                  {!deviationResolved && (
                    <TableRow>
                      <TableCell colSpan={9}>
                        <Box
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '10px',
                          }}
                        >
                          {item.paidAmount - item.totalPrice < 0 &&
                            !['unresolved_subscription_started'].includes(
                              item.deviationInfo.status,
                            ) &&
                            // Check if order is older than 1 month
                            new Date(item.created) <
                              new Date(
                                new Date().setMonth(new Date().getMonth() - 1),
                              ) && (
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ maxWidth: '150px' }}
                                onClick={() =>
                                  handleConfirmationOpen(
                                    'Skicka påminnelse',
                                    `Vill du skicka påminnelsen for ${item.label}?`,
                                    () => sendReminder(item.id),
                                    handleConfirmationCancel,
                                  )
                                }
                              >
                                Skicka påminnelse
                              </Button>
                            )}
                          {item.paidAmount - item.totalPrice < 0 &&
                            ![
                              'unresolved_reminder_sent',
                              'unresolved_subscription_started',
                            ].includes(item.deviationInfo.status) && (
                              <Button
                                variant="contained"
                                color="secondary"
                                style={{ maxWidth: '150px' }}
                                onClick={() =>
                                  handleConfirmationOpen(
                                    'Godkänn differens',
                                    `Vill du starta prenumerationen for ${item.label}?`,
                                    () => startSubscription(item.id),
                                    handleConfirmationCancel,
                                  )
                                }
                              >
                                Godkänn differens
                              </Button>
                            )}
                          {item.paidAmount - item.totalPrice > 0 && (
                            <Button
                              component={NavLink}
                              to={`/order/${item.id}/refund`}
                              variant="contained"
                              color="secondary"
                              style={{ textAlign: 'center', maxWidth: '150px' }}
                            >
                              Registrera återbetalning
                            </Button>
                          )}
                          <Button
                            variant="contained"
                            sx={theme => ({
                              maxWidth: '150px',
                              backgroundColor: theme.palette.error.main,
                              color: theme.palette.text.main,
                              '&:hover': {
                                background: theme.palette.error.dark,
                              },
                            })}
                            onClick={() =>
                              handleConfirmationOpen(
                                'Boka bort',
                                `Hantera ${item.label} som manuell bokföringsorder?`,
                                () => cancelDeviation(item.id),
                                handleConfirmationCancel,
                              )
                            }
                          >
                            Manuell bortbokning
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  Inga {deviationType.toLowerCase()} betalningsavvikelser finns
                  för denna filtrering. Prova att ange ett annat datumintervall.
                </TableCell>
              </TableRow>
            )}
          </TableList>
        </Card>
      </Box>
      {confirmationOpen && (
        <ConfirmationDialog
          open={confirmationOpen}
          title={confirmationTitle}
          message={confirmationMessage}
          onConfirm={confirmationAction}
          onCancel={cancelAction}
        />
      )}
    </>
  );
}

DeviationsListResults.propTypes = {
  params: PropTypes.shape({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    source: PropTypes.string,
    organization: PropTypes.string,
    deviationStatus: PropTypes.string,
    deviationResolved: PropTypes.bool,
    deviationInfo: PropTypes.object,
    handledFrom: PropTypes.string,
    handledTo: PropTypes.string,
  }),
};
