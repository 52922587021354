import { Add } from '@mui/icons-material';
import { Box, Button, MenuItem, TextField } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { termsStatus } from 'trhub-utils';

import useQueryParams from '~/hooks/useQueryParams';

import TermsListResults from './TermsListResults';

const initialValues = {
  status: 'all',
};

export default function TermsList() {
  const { state, params, handleChange } = useQueryParams(initialValues);
  const { pathname } = useLocation();

  return (
    <>
      <Box mb={2} display="flex" justifyContent="space-between">
        <TextField
          label="Status"
          variant="outlined"
          select
          value={state.status || 'all'}
          onChange={e => handleChange('status', e.target.value)}
          css={{
            minWidth: '150px',
          }}
        >
          <MenuItem value="all">Alla</MenuItem>
          {Object.entries(termsStatus).map(([key, value]) => (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <Button
          component={Link}
          to={`${pathname}/add`}
          color="primary"
          variant="contained"
          startIcon={<Add />}
        >
          Lägg till
        </Button>
      </Box>
      <TermsListResults params={params} />
    </>
  );
}
