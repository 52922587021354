import { Chip, Link } from '@mui/material';
import { green, red, yellow } from '@mui/material/colors';
import { differenceInDays } from 'date-fns';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { paymentMethods } from 'trhub-utils';

import DataGrid from '~/components/DataGrid';
import Time from '~/components/Time';

import query from './_GetSubscriptions.gql';

const columns = [
  {
    field: 'status',
    width: 77,
    headerName: 'Status',
    sortable: false,
    valueGetter: params =>
      differenceInDays(new Date(params.row.dueDate), Date.now()),
    renderCell: params => {
      const diff = differenceInDays(Date.now(), new Date(params.row.dueDate));

      if (!params.row.isActive) {
        return <Chip color="default" label="Avslutad" size="small" />;
      }

      return diff < 0 ? (
        <Chip
          label="ok"
          size="small"
          css={{
            background: green[500],
            color: 'white',
          }}
        />
      ) : (
        <Chip
          label={diff === 0 ? 'Pending' : `+${diff}d`}
          size="small"
          css={{
            background: diff === 0 ? yellow[500] : red[500],
            color: diff === 0 ? 'black' : 'white',
          }}
        />
      );
    },
  },
  {
    field: 'product',
    flex: 3,
    headerName: 'Produkt',
    sortable: false,
    renderCell: params => (
      <Link component={RouterLink} to={`/subscriptions/${params.row.id}`}>
        {params.value.name}
      </Link>
    ),
  },
  {
    field: 'user',
    flex: 2,
    headerName: 'Användare',
    sortable: false,
    renderCell: params => (
      <Link component={RouterLink} to={`/users/${params.value.id}`}>
        {params.value.email || <em>E-post saknas</em>}
      </Link>
    ),
  },
  {
    field: 'paymentMethod',
    flex: 1,
    headerName: 'Betalmetod',
    sortable: false,
    valueGetter: params =>
      params.row.paymentMethod && paymentMethods[params.row.paymentMethod],
  },
  {
    field: 'startDate',
    flex: 1,
    headerName: 'Start',
    renderCell: params => <Time date={new Date(params.value)} />,
  },
  {
    field: 'dueDate',
    flex: 1,
    headerName: 'Förfallodatum',
    renderCell: params => <Time date={new Date(params.value)} />,
  },
  {
    field: 'endDate',
    flex: 1,
    headerName: 'Slut',
    renderCell: params =>
      params.value && <Time date={new Date(params.value)} />,
  },
];

export default function SubscriptionListResults({ params }) {
  const { paymentMethod, active, user, sites, notEnded, product, dueDate } =
    params;

  return (
    <DataGrid
      columns={columns}
      query={query}
      defaultSort="-startDate"
      filter={{
        user,
        sites,
        active: active === 'all' || active === '' ? undefined : active,
        paymentMethod: paymentMethod === 'all' ? undefined : paymentMethod,
        notEnded: notEnded === 'all' || notEnded === '' ? undefined : notEnded,
        product,
        dueDate: dueDate ? new Date(dueDate) : undefined,
      }}
    />
  );
}

SubscriptionListResults.propTypes = {
  params: PropTypes.shape({
    paymentMethod: PropTypes.string,
    active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    user: PropTypes.string,
    sites: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    notEnded: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    dueDate: PropTypes.string,
    product: PropTypes.string,
  }),
};
