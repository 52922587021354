import { Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: '400px',
  },
}));
export default function CardPaymentsChart({ label, data, axis }) {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {label}
      </Typography>

      <ResponsiveContainer>
        <LineChart data={data}>
          {axis.map(item => (
            <Line
              key={item.key}
              type="monotone"
              dataKey={item.key}
              name={item.label}
              stroke={item.stroke}
              yAxisId={item.yAxisId || 'left'}
            />
          ))}

          <CartesianGrid stroke="#ccc" />
          <XAxis dataKey="id" />
          <YAxis yAxisId="left" />
          <YAxis orientation="right" yAxisId="right" />
          <Tooltip formatter={value => `${value.toLocaleString()}`} />
        </LineChart>
      </ResponsiveContainer>
    </Paper>
  );
}

CardPaymentsChart.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  axis: PropTypes.array.isRequired,
};
