import { useQuery } from '@apollo/client';
import {
  Chip,
  CircularProgress,
  Link,
  TableCell,
  TableRow,
} from '@mui/material';
import { formatMoney } from '@trmediaab/zebra-utils';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  orderStatus as orderStatusTypes,
  paymentStatus as paymentStatusTypes,
} from 'trhub-utils';

import ErrorMessage from '~/components/ErrorMessage';
import NoData from '~/components/NoData';
import PaymentMethodIcon from '~/components/PaymentMethodIcon';
import TableList from '~/components/TableList';
import Time from '~/components/Time';
import usePagination from '~/hooks/usePagination';

import query from './_GetOrders.gql';

const OrderList = ({
  filter,
  button,
  size = 10,
  orderBuilderLabel = false,
  showUser = false,
}) => {
  const { page, pageSize, updatePage, updateRowSize } = usePagination(size);

  const { error, loading, data } = useQuery(query, {
    variables: { filter, page: page, limit: pageSize },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (loading) {
    return <CircularProgress />;
  }

  const headers = showUser
    ? ['', 'Datum', 'Order', 'Användare', 'Pris', 'Status', 'Betalning']
    : ['', 'Datum', 'Order', 'Pris', 'Status', 'Betalning'];

  const res = data?.orders;

  return (
    <TableList
      title="Ordrar"
      page={page}
      pageSize={pageSize}
      data={res}
      headers={headers}
      updatePage={updatePage}
      updateRowSize={updateRowSize}
      button={button}
    >
      {res?.items?.map(order => (
        <TableRow key={order.id}>
          <TableCell>
            <PaymentMethodIcon paymentMethod={order.paymentMethod} />
          </TableCell>
          <TableCell>
            <Time date={new Date(order.created)} />
          </TableCell>
          <TableCell>
            <Link component={RouterLink} to={`/order/${order.id}`}>
              {orderBuilderLabel ? order.orderBuilder?.name : order.label}
            </Link>
          </TableCell>
          {showUser && (
            <TableCell>
              <Link component={RouterLink} to={`/users/${order.user.id}`}>
                {order.user.name || order.user.email}
              </Link>
            </TableCell>
          )}
          <TableCell>{formatMoney(order.totalPrice)}</TableCell>
          <TableCell>
            <Chip
              color="primary"
              label={orderStatusTypes[order.orderStatus]}
              size="small"
            />
          </TableCell>
          <TableCell>
            <Chip
              color="primary"
              label={
                order.paymentStatus
                  ? paymentStatusTypes[order.paymentStatus]
                  : 'Info saknas'
              }
              size="small"
            />
          </TableCell>
        </TableRow>
      ))}

      {res?.items.length === 0 && (
        <TableRow>
          <TableCell colSpan={headers.length}>
            <NoData text="Det finns inga ordrar" />
          </TableCell>
        </TableRow>
      )}
    </TableList>
  );
};

OrderList.propTypes = {
  filter: PropTypes.shape({
    user: PropTypes.string,
    subscription: PropTypes.string,
    orderBuilder: PropTypes.string,
  }),
  button: PropTypes.node,
  size: PropTypes.number,
  orderBuilderLabel: PropTypes.bool,
  showUser: PropTypes.bool,
};

export default OrderList;
