import { useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { Switch, useParams, useRouteMatch } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';
import ErrorMessage from '~/components/ErrorMessage';

import query from './_GetProduct.gql';
import ProductDetail from './ProductDetail';
import ProductEdit from './ProductEdit';

export default function Product() {
  const { path } = useRouteMatch();
  const { product } = useParams();
  const { error, loading, data, refetch } = useQuery(query, {
    variables: { product },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Breadcrumb name={data.getProduct.name} />
      <Switch>
        <ErrorBoundRoute path={path} exact>
          <ProductDetail productData={data} refetch={refetch} />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/edit`} exact>
          <ProductEdit productData={data} />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
