import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorMessage from '~/components/ErrorMessage';
import { userPropType } from '~/propTypes';

import mutation from './_EditUser.gql';
import UserForm from './UserForm';

export default function UserEdit({ userData }) {
  const { user } = useParams();

  const [action, { error }] = useMutation(mutation, {
    refetchQueries: ['GetUser'],
  });

  const history = useHistory();

  const {
    companyName,
    firstName,
    lastName,
    email,
    mobile,
    customerNumber,
    personalIdentityNumber,
    organizationNumber,
    collectionPayee,
    address,
    distributor,
    roles,
  } = userData.getUser;

  if (error) {
    return <ErrorMessage error={error} />;
  }
  return (
    <>
      <Breadcrumb name="Redigera" />
      <UserForm
        initialValues={{
          companyName: companyName || '',
          firstName: firstName || '',
          lastName: lastName || '',
          email: email || '',
          customerNumber: customerNumber || undefined,
          personalIdentityNumber: personalIdentityNumber || '',
          organizationNumber: organizationNumber || '',
          collectionPayee: collectionPayee || false,
          mobile: mobile || '',
          careOf: address.careOf || '',
          address: address.address || '',
          distributor: distributor || '',
          postalCode: address.postalCode?.replace(/\s/g, '') ?? '',
          city: address.city || '',
          country: address.country || '',
          roles,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const {
            careOf,
            address,
            distributor,
            postalCode,
            city,
            country,
            customerNumber,
            personalIdentityNumber,
            organizationNumber,
            ...rest
          } = values;

          const modifiedValues = {
            ...rest,
            customerNumber: customerNumber === '' ? null : customerNumber,
            personalIdentityNumber:
              personalIdentityNumber === '' ? null : personalIdentityNumber,
            organizationNumber:
              organizationNumber === '' ? null : organizationNumber,
            address: {
              careOf,
              address,
              postalCode,
              city,
              country,
            },
            distributor: distributor === '' ? null : distributor,
          };

          const response = await action({
            variables: {
              id: user,
              input: modifiedValues,
            },
          });

          setSubmitting(false);

          if (response) {
            history.push(`/users/${user}`);
          }
        }}
      />
    </>
  );
}

UserEdit.propTypes = {
  userData: PropTypes.shape({
    getUser: userPropType,
  }).isRequired,
};
