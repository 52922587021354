import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  LinearProgress,
  MenuItem,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { complaintCodes, complaintCodesByDamaged } from 'trhub-utils';
import * as Yup from 'yup';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorMessage from '~/components/ErrorMessage';
import { userPropType } from '~/propTypes';
import toOptions from '~/utils/toOptions';

import mutation from './_DistributionComplaint.gql';
import query from './_GetDistribution.gql';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    marginBottom: theme.spacing(3),
  },
}));

const validationSchema = Yup.object().shape({
  reason: Yup.string().required('Välj en orsak till reklamationen'),
  secondaryReason: Yup.string().when('reason', {
    is: value => value === '2',
    then: Yup.string().required('Välj ett alternativ'),
  }),
});

export default function UserDistributionComplaint({ userData }) {
  const classes = useStyles();

  const { distributionId } = useParams();

  const [action] = useMutation(mutation, {
    onCompleted: async () => {
      // Ensure all lists are refreshed
      await apollo.resetStore();
    },
  });

  const user = userData?.getUser;

  const apollo = useApolloClient();

  const history = useHistory();

  const { error, loading, data } = useQuery(query, {
    variables: { distribution: distributionId },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (loading) {
    return <CircularProgress />;
  }

  const distribution = data?.getDistribution;

  return (
    <>
      <Breadcrumb name="Reklamera" />
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Reklamera en utgåva
          </Typography>
          <Typography
            variant="body2"
            component="p"
            sx={theme => ({
              marginTop: theme.spacing(2),
            })}
          >
            Ange kundens anledning för reklamationen. Det går inte att ändra på
            anledningen i efterhand.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            sx={theme => ({
              marginTop: theme.spacing(2),
            })}
          >
            Om tidningen är kopplad till en månads- eller årsprenumeration med
            kortbetalning ellers årsbetalning med avisering, så flyttas
            förfallodatumet fram en vecka som kompensation. Om prenumerationen
            är kopplad till autogiro, och det har skett fyra reklamationer från
            kunden så blir nästkommande månad gratis.
          </Typography>
          {distribution.complaint?.reason && (
            <Typography
              variant="body2"
              component="p"
              sx={theme => ({
                marginTop: theme.spacing(2),
              })}
            >
              Den här utgåvan är redan reklamerad.
            </Typography>
          )}
        </CardContent>
      </Card>

      <Formik
        initialValues={{
          reason: '',
          secondaryReason: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          const response = await action({
            variables: {
              input: {
                user: user.id,
                distributionId,
                reason: Number.parseInt(
                  values.secondaryReason || values.reason,
                  10,
                ),
              },
            },
          });
          setSubmitting(false);

          if (response) {
            history.push(`/users/${user.id}`);
          }
        }}
        validateOnBlur={false}
      >
        {({ submitForm, isSubmitting, values }) => (
          <Form
            css={({ theme }) => ({
              '> * + *': {
                marginTop: theme.spacing(2),
              },
              '> *': {
                width: '100%',
              },
              '> .MuiFormControl-root': {
                backgroundColor: theme.palette.background.default,
              },
            })}
          >
            <Field
              component={TextField}
              select
              name="reason"
              label="Ange orsak till reklamation"
              variant="outlined"
            >
              {toOptions(complaintCodes).map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </Field>
            {values.reason === '2' && (
              <Field
                component={TextField}
                select
                name="secondaryReason"
                label="Hur var tidningen skadad?"
                variant="outlined"
              >
                {toOptions(complaintCodesByDamaged).map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
              </Field>
            )}
            {isSubmitting && <LinearProgress />}
            <Box>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting || distribution.complaint?.reason}
                onClick={submitForm}
              >
                Spara
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}

UserDistributionComplaint.propTypes = {
  userData: PropTypes.shape({
    getUser: userPropType,
  }).isRequired,
};
