import { useMutation } from '@apollo/client';
import { Delete as DeleteIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import ErrorMessage from '~/components/ErrorMessage';
import TablePaper from '~/components/TablePaper';
import Time from '~/components/Time';

import AddMessage from './_AddUserLogMessage.gql';
import RemoveMessage from './_RemoveUserLogMessage.gql';

const Events = ({ items, user }) => {
  const [add, { loading, error: addError }] = useMutation(AddMessage);
  const [remove, { loading: removing, error: removeError }] =
    useMutation(RemoveMessage);

  const [message, setMessage] = useState('');

  const submitMessage = useCallback(() => {
    add({
      variables: {
        message,
        user,
      },
    })
      .then(() => setMessage(''))
      .catch(console.error);
  }, [user, message, add]);

  const removeMessage = useCallback(
    id => {
      remove({
        variables: {
          id,
          user,
        },
      }).catch(console.error);
    },
    [user, remove],
  );

  const onChange = useCallback(event => {
    const { value } = event.target;
    setMessage(value);
  }, []);

  return (
    <TablePaper title="Logg">
      {removeError && (
        <Box my={1}>
          <ErrorMessage error={removeError} />
        </Box>
      )}
      <List css={{ maxHeight: '800px', overflowY: 'auto' }}>
        {[...items].reverse().map(item => (
          <ListItem key={item.id} disableGutters divider>
            <ListItemText
              primaryTypographyProps={{
                variant: 'body2',
                component: 'div',
                color: 'textSecondary',
              }}
              secondaryTypographyProps={{
                variant: 'body2',
                color: 'textPrimary',
              }}
              primary={
                <>
                  {item.user && (
                    <>
                      <Link to={`/users/${item.user.id}`}>
                        {item.user.name}
                      </Link>
                      {' | '}
                    </>
                  )}
                  <Time
                    date={new Date(item.timestamp)}
                    format="yyyy-MM-dd HH:mm"
                  />
                  <Box position="absolute" right="0" top="0" py="8px" px="3px">
                    <IconButton
                      onClick={() => removeMessage(item.id)}
                      disabled={removing}
                      aria-label="delete"
                      size="small"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </>
              }
              secondary={
                <Box
                  component="span"
                  sx={{
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {item.message}
                </Box>
              }
            />
          </ListItem>
        ))}
      </List>

      <Box my={2}>
        <FormControl fullWidth>
          <TextField
            name="message"
            label="Meddelande"
            variant="outlined"
            id="message"
            multiline
            value={message}
            onChange={onChange}
            minRows={4}
          />
        </FormControl>
      </Box>
      {addError && <ErrorMessage error={addError} />}
      <Button
        variant="contained"
        color="primary"
        onClick={submitMessage}
        disabled={message === '' || loading}
      >
        Spara
      </Button>
    </TablePaper>
  );
};

Events.propTypes = {
  user: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      timestamp: PropTypes.string.isRequired,
      user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    }),
  ),
};

export default Events;
