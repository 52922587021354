import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { formatMoney } from '@trmediaab/zebra-utils';
import { Link as RouterLink } from 'react-router-dom';
import { lineItemTypes } from 'trhub-utils';

import NoUserData from '~/components/NoData';
import TablePaper from '~/components/TablePaper';
import { orderPropType } from '~/propTypes';

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function OrderLineItems({ order }) {
  const { lineItems, netPrice, totalVAT, totalPrice, campaign, orderBuilder } =
    order;
  return (
    <TablePaper title="Varor">
      {lineItems.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Vara</TableCell>
                <TableCell align="right">Antal</TableCell>
                <TableCell align="right">Á pris</TableCell>
                <TableCell align="right">Moms</TableCell>
                <TableCell align="right">Rabatt</TableCell>
                <TableCell align="right">Summa</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lineItems.map(
                (lineItem, index) =>
                  lineItem && (
                    <StyledTableRow key={index}>
                      <TableCell component="th" scope="row">
                        {lineItem.product ? (
                          <Link
                            component={RouterLink}
                            to={
                              orderBuilder
                                ? `/order-builders/${orderBuilder.id}`
                                : `/products/${lineItem.product.id}`
                            }
                          >
                            {orderBuilder
                              ? orderBuilder.name
                              : lineItem.product?.name}{' '}
                          </Link>
                        ) : (
                          lineItemTypes[lineItem.type]
                        )}{' '}
                        {lineItem.label !== lineItem.product?.name
                          ? lineItem.label
                          : ''}
                        {lineItem.subscription && (
                          <>
                            |{' '}
                            <Link
                              component={RouterLink}
                              to={`/subscriptions/${lineItem.subscription.id}`}
                            >
                              {lineItem.subscription.user.name ||
                                lineItem.subscription.user.email}{' '}
                            </Link>
                          </>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {lineItem.quantity || '1'}
                      </TableCell>
                      <TableCell align="right">
                        {formatMoney(lineItem.price)}
                      </TableCell>
                      <TableCell align="right">{lineItem.vat}%</TableCell>
                      <TableCell align="right">
                        {lineItem.discount
                          ? formatMoney(lineItem.discount)
                          : ''}
                      </TableCell>
                      <TableCell align="right">
                        {formatMoney(
                          lineItem.price * (lineItem.quantity || 1) -
                            (lineItem.discount || 0),
                        )}
                      </TableCell>
                    </StyledTableRow>
                  ),
              )}
              {campaign && (
                <TableRow>
                  <TableCell
                    css={`
                      border: 0;
                    `}
                  />
                  <TableCell colSpan={1} align="right">
                    <Box fontWeight="fontWeightBold">Kampanj</Box>
                  </TableCell>
                  <TableCell colSpan={4} align="right">
                    <Link
                      component={RouterLink}
                      to={`/campaigns/${campaign.id}`}
                    >
                      {campaign.code || campaign.name}
                    </Link>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell
                  rowSpan={3}
                  css={`
                    border: 0;
                  `}
                />
                <TableCell align="right">
                  <Box fontWeight="fontWeightBold">Nettobelopp</Box>
                </TableCell>
                <TableCell colSpan={4} align="right">
                  {formatMoney(netPrice)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right">
                  <Box fontWeight="fontWeightBold">Moms</Box>
                </TableCell>
                <TableCell colSpan={4} align="right">
                  {formatMoney(totalVAT)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right">
                  <Box fontWeight="fontWeightBold">Totalt</Box>
                </TableCell>
                <TableCell colSpan={4} align="right">
                  <Box fontWeight="fontWeightBold">
                    {formatMoney(totalPrice)}
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NoUserData text="Den här ordern saknar varor" />
      )}
    </TablePaper>
  );
}

OrderLineItems.propTypes = {
  order: orderPropType,
};
