import { Card, CardContent, Typography } from '@mui/material';

export default function StatusInfo() {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="h2">
          Statusindikationer
        </Typography>
        <Typography
          variant="body2"
          component="p"
          sx={theme => ({
            marginTop: theme.spacing(2),
          })}
        >
          Utkast: Skapad men ej skickad girokampanj. För att den ska placeras i
          kö för utskick krävs det att en CSV-fil med lista på användar-IDn
          (t.ex. 6363811720c6dabef224fbf4, 6363812ba41a608836b910e8...) och en
          PDF huvudförnyelse bifogas, samt att man trycker på SKICKA.
        </Typography>
        <Typography
          variant="body2"
          component="p"
          sx={theme => ({
            marginTop: theme.spacing(2),
          })}
        >
          Klar att skickas: Girokampanjen är placerad i kö för aviutskick till
          kunderna från CSV-filen. Det går inte att redigera girokampanjen
          längre.
        </Typography>
        <Typography
          variant="body2"
          component="p"
          sx={theme => ({
            marginTop: theme.spacing(2),
          })}
        >
          Pågående: Bakgrundsjobbet är igång som skickar ut avierna till
          kunderna. Status om skickade och misslyckade uppdateras kontinuerligt.
        </Typography>
        <Typography
          variant="body2"
          component="p"
          sx={theme => ({
            marginTop: theme.spacing(2),
          })}
        >
          Slutförd: Alla fakturor har skickats ut.
        </Typography>
      </CardContent>
    </Card>
  );
}
