import { useQuery } from '@apollo/client';
import { CircularProgress, Link, TableCell, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { formatMoney } from '@trmediaab/zebra-utils';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import ErrorMessage from '~/components/ErrorMessage';
import TableList from '~/components/TableList';
import Time from '~/components/Time';
import usePagination from '~/hooks/usePagination';

import query from './_GetUnusedGiftCodePurchaseOrders.gql';

const useStyles = makeStyles(() => ({
  tableRowSum: {
    background: 'linear-gradient(45deg, #2196F3 5%, #21CBF3 30%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
  },
}));

export default function UnusedGiftCodesListResult({ params }) {
  const classes = useStyles();
  const { from, to, productId } = params;
  const { page, pageSize, updatePage, updateRowSize } = usePagination(15);

  const { error, loading, data } = useQuery(query, {
    variables: {
      page: page,
      limit: pageSize,
      filter: {
        from,
        to,
        productId,
      },
    },
  });
  const res = data?.list;

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (loading) {
    return <CircularProgress />;
  }

  const headers = ['Kod', 'Ködatum', 'Produkt', 'Pris', 'Förfallodatum'];

  return (
    <TableList
      title="Onyttjade värdekoder"
      page={page}
      pageSize={pageSize}
      data={res}
      headers={headers}
      updatePage={updatePage}
      updateRowSize={updateRowSize}
    >
      {res?.items.length > 0 ? (
        res.items.map((order, index) => (
          <Fragment key={index}>
            <TableRow>
              <TableCell className={classes.siteRowCell}>
                <Link component={RouterLink} to={`/orders/${order._id}`}>
                  {order.giftCode}
                </Link>
              </TableCell>
              <TableCell className={classes.siteRowCell}>
                <Time
                  key={`${order._id}_created`}
                  date={new Date(order.created)}
                  format="yyyy-MM-dd HH:mm"
                />
              </TableCell>
              <TableCell className={classes.siteRowCell}>
                <Link
                  component={RouterLink}
                  to={`/products/${order.lineItems[0].product.id}`}
                >
                  {order.label}
                </Link>
              </TableCell>

              <TableCell className={classes.siteRowCell}>
                {formatMoney(order.totalPrice)}
              </TableCell>
              <TableCell className={classes.siteRowCell}>
                <Time
                  key={`${order._id}_giftExpiryDate`}
                  date={new Date(order.giftExpiryDate)}
                  format="yyyy-MM-dd HH:mm"
                />
              </TableCell>
            </TableRow>
          </Fragment>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={10} align="center">
            Inget rapportdata finns för denna filtrering.
          </TableCell>
        </TableRow>
      )}
    </TableList>
  );
}

UnusedGiftCodesListResult.propTypes = {
  params: PropTypes.shape({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired,
  }).isRequired,
};
