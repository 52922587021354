import { Redirect, Switch } from 'react-router-dom';

import ErrorBoundRoute from '~/components/ErrorBoundRoute';
import useAuthUser from '~/hooks/useAuthUser';

import Attest from './Attest';
import Campaign from './Campaign';
import Edition from './Edition';
import Issue from './Issue';
import Oauth from './Oauth';
import Order from './Order';
import OrderBuilder from './OrderBuilder';
import Product from './Product';
import Reports from './Reports';
import Statistics from './Statistics';
import Subscription from './Subscription';
import Terms from './Terms';
import Users from './Users';

export default function Routes() {
  const { roles } = useAuthUser();
  let startRoute = '/users';
  if (roles.includes('banprogram:distributor')) {
    startRoute = '/editions';
  }
  return (
    <Switch>
      <ErrorBoundRoute path="/users">
        <Users />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/order">
        <Order />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/products">
        <Product />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/subscriptions">
        <Subscription />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/campaigns">
        <Campaign />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/refunds">
        <Attest />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/editions">
        <Edition />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/order-builders">
        <OrderBuilder />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/reports">
        <Reports />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/statistics">
        <Statistics />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/issues">
        <Issue />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/settings/terms">
        <Terms />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/settings/oauth">
        <Oauth />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/" exact>
        <Redirect to={startRoute} />
      </ErrorBoundRoute>
    </Switch>
  );
}
