import { useQuery } from '@apollo/client';
import { Alert, TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Field, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import query from '~/utils/_GetProducts.gql';

export default function ProductAutocomplete({ filter, defaultValue }) {
  const { errors, setFieldValue } = useFormikContext();
  const [productSearch, setProductSearch] = useState('');

  const refetchProduct = useCallback(search => {
    setProductSearch(search);
  }, []);

  const { loading, error, data } = useQuery(query, {
    variables: {
      filter: {
        productType: filter?.type,
        skus: filter?.skus,
        search: productSearch,
      },
    },
  });

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  const productList = data?.list?.items ?? [];

  return (
    <>
      <Field
        noOptionsText={loading ? 'Laddar in produkter...' : 'Inga träffar.'}
        name="product"
        component={Autocomplete}
        filterSelectedOptions
        options={productList}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={option => option.name}
        filterOptions={createFilterOptions({
          limit: 150,
        })}
        onInputChange={async (_, value) => {
          refetchProduct(value);
        }}
        defaultValue={defaultValue || null}
        onChange={(_, value) => setFieldValue('product', value)}
        renderInput={params => (
          <TextField
            {...params}
            label="Sök produkt"
            variant="outlined"
            error={errors.product != null}
            helperText={errors.product}
          />
        )}
      />
    </>
  );
}

ProductAutocomplete.propTypes = {
  filter: PropTypes.shape({
    type: PropTypes.string,
    skus: PropTypes.arrayOf(PropTypes.string),
  }),
  defaultValue: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }),
};
