import { useQuery } from '@apollo/client';
import { CircularProgress, Link, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import ErrorMessage from '~/components/ErrorMessage';
import TableList from '~/components/TableList';
import usePagination from '~/hooks/usePagination';

import query from './_GetDistributions.gql';

export default function DistributionListResults({ edition, params }) {
  const { page, pageSize, updatePage, updateRowSize } = usePagination();
  const { hasError, distributionDate } = params;
  const { error, data } = useQuery(query, {
    variables: {
      edition,
      filter: {
        hasError,
        distributionDate,
      },
      page,
      limit: pageSize,
    },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }

  const res = data?.getDistributions;

  if (!res) {
    return <CircularProgress />;
  }

  const headers = ['Namn', 'Address', 'Antal', 'Fel', 'Distributör'];

  return (
    <TableList
      page={page}
      pageSize={pageSize}
      data={res}
      headers={headers}
      updatePage={updatePage}
      updateRowSize={updateRowSize}
    >
      {res?.distributions.length > 0 ? (
        res.distributions.map(distribution => (
          <TableRow hover key={distribution.id}>
            <TableCell>
              <Link
                component={RouterLink}
                to={`/users/${distribution.user.id}`}
              >
                {distribution.user.name || 'Namn saknas'}
              </Link>
            </TableCell>
            <TableCell>
              {distribution.address && (
                <>
                  {distribution.address.address},{' '}
                  {distribution.address.postalCode} {distribution.address.city}
                </>
              )}
            </TableCell>
            <TableCell>{distribution.quantity}</TableCell>
            <TableCell>{distribution.error}</TableCell>
            <TableCell>{distribution.distributor}</TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={4} align="center">
            <em>Ingen distribution hittades.</em>
          </TableCell>
        </TableRow>
      )}
    </TableList>
  );
}

DistributionListResults.propTypes = {
  edition: PropTypes.string.isRequired,
  params: PropTypes.shape({
    hasError: PropTypes.bool.isRequired,
    distributionDate: PropTypes.string,
  }),
};
