import { Link } from '@mui/material';
import { formatMoney } from '@trmediaab/zebra-utils';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { campaignTypes } from 'trhub-utils';

import DataGrid from '~/components/DataGrid';
import query from '~/utils/_GetCampaigns.gql';

const columns = [
  {
    field: 'name',
    flex: 2,
    headerName: 'Kampanj',
    renderCell: params => (
      <Link component={RouterLink} to={`/campaigns/${params.row.id}`}>
        {params.value}
      </Link>
    ),
  },
  {
    field: 'code',
    flex: 1,
    headerName: 'Rabattkod',
    sortable: false,
  },
  {
    field: 'productsLabel',
    flex: 2,
    headerName: 'Produkter',
    sortable: false,
  },
  {
    field: 'type',
    flex: 1,
    headerName: 'Typ',
    sortable: false,
    valueGetter: params => campaignTypes[params.value] || ' - ',
  },

  {
    field: 'price',
    flex: 1,
    headerName: 'Pris',
    valueFormatter: params => formatMoney(params.value),
  },
  {
    field: 'usage',
    flex: 1,
    headerName: 'Utnyttjad',
  },
];
export default function CampaignListResults({ params }) {
  const { search, site, active } = params;

  return (
    <DataGrid
      columns={columns}
      query={query}
      defaultSort="name"
      filter={{
        search,
        site,
        active: active === 'all' ? undefined : active,
      }}
    />
  );
}

CampaignListResults.propTypes = {
  params: PropTypes.shape({
    search: PropTypes.string.isRequired,
    active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    site: PropTypes.string,
  }),
};
