import {
  AccountBalance,
  Alarm,
  AttachMoney,
  Autorenew,
  BarChart,
  CalendarToday,
  CancelPresentation,
  ConfirmationNumber,
  Email,
  Error as ErrorIcon,
  FormatListBulleted,
  GavelOutlined,
  GetApp,
  LibraryBooks,
  ListAlt,
  LocalOffer,
  PanTool,
  People,
  Public,
  Refresh,
  ReportProblem,
  Settings,
  ShoppingCart,
  Store,
  SupervisorAccount,
  Timeline,
  VpnKey,
} from '@mui/icons-material';

const menus = {
  primary: [
    {
      text: 'Användare',
      path: '/users',
      icon: People,
      access: 'getUsers',
    },
    {
      text: 'Ordrar',
      path: '/order',
      icon: ShoppingCart,
      access: 'getOrders',
    },
    {
      text: 'Produkter',
      path: '/products',
      icon: People,
      access: 'getProducts',
    },
    {
      text: 'Prenumerationer ',
      path: '/subscriptions',
      icon: Refresh,
      access: 'getSubscriptions',
    },
    {
      text: 'Kampanjer',
      path: '/campaigns',
      icon: Alarm,
      access: 'getCampaigns',
    },
    {
      text: 'Utgåvor',
      path: '/editions',
      icon: LocalOffer,
      access: 'getEditions',
    },
    {
      text: 'Girokampanjer',
      path: '/order-builders',
      icon: ListAlt,
      access: 'getOrderBuilders',
    },
  ],
  secondary: [
    {
      text: 'Marknad',
      icon: Store,
      path: '/reports',
      subMenu: [
        {
          text: 'Försäljning',
          path: '/sales',
          icon: AttachMoney,
        },
        {
          text: 'Nyckeltal',
          path: '/keymetrics',
          icon: Timeline,
        },
        {
          text: 'Uppsägningar',
          path: '/unsubscribe-reasons',
          icon: CancelPresentation,
        },
        {
          text: 'Reklamationer',
          path: '/complaints',
          icon: ErrorIcon,
        },
        {
          text: 'Kortbetalningar',
          path: '/card-payments',
          icon: Autorenew,
        },
        {
          text: 'Avgångsstatistik',
          path: '/subscriptions-expiring',
          icon: CalendarToday,
        },
        {
          text: 'Avslutade prenumerationer',
          path: '/canceled-subscriptions',
          icon: PanTool,
        },
        { text: 'Värdekodslista', path: '/gift_codes', icon: Email },
      ],
    },
    {
      text: 'Ekonomi',
      icon: LibraryBooks,
      path: '/reports',
      subMenu: [
        {
          text: 'Försäljning',
          path: '/economy_sales',
          icon: AttachMoney,
        },
        {
          text: 'OSS',
          path: '/moss',
          icon: Public,
        },
        { text: 'Avvikelser', path: '/deviations', icon: ReportProblem },
        { text: 'Prenskuld', path: '/debt', icon: AccountBalance },
        { text: 'Värdekodsskuld', path: '/gift_code_debt', icon: Email },
        { text: 'Export', path: '/economy_export', icon: GetApp },
      ],
    },
    {
      text: 'Statistik',
      path: '/statistics',
      icon: BarChart,
      subMenu: [
        {
          text: 'Prenumerationer',
          path: '/prenstats',
          icon: Refresh,
        },
        {
          text: 'Speltips',
          path: '/bettipstats',
          icon: ConfirmationNumber,
        },
      ],
    },
    {
      text: 'Återbetalningar',
      path: '/refunds',
      icon: SupervisorAccount,
      badge: 'getOpenRefundsCount',
    },
    {
      text: 'Ärenden',
      path: '/issues',
      icon: FormatListBulleted,
      badge: 'getNewIssuesCount',
    },
    {
      text: 'Inställningar',
      icon: Settings,
      path: '/settings',
      subMenu: [
        {
          text: 'Allmänna villkor',
          path: '/terms',
          icon: GavelOutlined,
        },
        {
          text: 'Oauth',
          path: '/oauth',
          icon: VpnKey,
        },
      ],
    },
  ],
};

export default menus;
