import { useQuery } from '@apollo/client';
import { GetApp } from '@mui/icons-material';
import { Box, Button, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import { useState } from 'react';

import ErrorMessage from '~/components/ErrorMessage';
import SelectField from '~/components/Select';
import usePagination from '~/hooks/usePagination';
import useQueryParams from '~/hooks/useQueryParams';
import shallowEqual from '~/utils/shallowEqual';

import GetMossReport from './_GetMossReport.gql';
import MossListResults from './MossListResult';

const useStyles = makeStyles(theme => ({
  textField: {
    maxWidth: 350,
    minWidth: 175,
    marginTop: 10,
  },
  box: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

// Get quarters since 2020
const getQuarters = () => {
  const currentDate = new Date(),
    year = currentDate.getFullYear(),
    month = currentDate.getMonth(),
    quarters = ['Q1', 'Q2', 'Q3', 'Q4'],
    options = [];

  for (let startDate = 2020; startDate < year; startDate++) {
    quarters.forEach(q =>
      options.push({ value: `${q} ${startDate}`, label: `${q} ${startDate}` }),
    );
  }
  quarters
    .slice(0, Number.parseInt(month / 3 + 1))
    .forEach(q =>
      options.push({ value: `${q} ${year}`, label: `${q} ${year}` }),
    );

  return options;
};

const initialValues = {
  month: format(new Date(), 'yyyy-MM'),
  quarter: '',
};

export default function MossList() {
  const classes = useStyles();

  const { state, params, clear, handleChanges } = useQueryParams(initialValues);

  const [error] = useState({ message: '' });

  const urlParams = new URLSearchParams();

  if (state.month) {
    urlParams.set('month', state.month);
  }
  if (state.quarter) {
    urlParams.set('quarter', params.quarter);
  }

  const quarters = getQuarters().reverse();
  const { updatePage, updateRowSize } = usePagination(15);

  const {
    error: resultError,
    loading: resultLoading,
    data: resultData,
  } = useQuery(GetMossReport, {
    variables: {
      month: params.month,
      quarter: params.quarter,
    },
  });

  if (error.message) {
    return <ErrorMessage error={error} />;
  }

  return (
    <>
      <Box className={classes.box} display="flex">
        <Box
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          columnGap="10px"
          marginTop="-10px"
        >
          <TextField
            className={classes.textField}
            label="Månad"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="month"
            value={state.month}
            onChange={e =>
              handleChanges({ quarter: '', month: e.target.value })
            }
          />
          <SelectField
            className={classes.textField}
            label="Kvartal"
            value={state.quarter}
            onChange={e =>
              handleChanges({ month: '', quarter: e.target.value })
            }
            options={quarters}
          />
          {!shallowEqual(initialValues, state) && (
            <Button
              color="primary"
              sx={theme => ({
                margin: theme.spacing(1),
                textTransform: 'none',
              })}
              onClick={clear}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>
        <Box ml="auto">
          <Button
            color="primary"
            variant="contained"
            startIcon={<GetApp />}
            component="a"
            target="_blank"
            href={`/api/export/moss?${urlParams.toString()}`}
            rel="noreferrer"
          >
            OSSRAPPORT
          </Button>
        </Box>
      </Box>
      <MossListResults
        params={params}
        loading={resultLoading}
        error={resultError}
        updatePage={updatePage}
        updateRowSize={updateRowSize}
        data={resultData}
      />
    </>
  );
}
