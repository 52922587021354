import {
  Alert,
  Avatar,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { NavLink, useLocation } from 'react-router-dom';
import { auctionParticipationRole, Types, userRoles } from 'trhub-utils';

import Address from '~/components/Address';
import TablePaper from '~/components/TablePaper';
import Time from '~/components/Time';
import useAuthUser from '~/hooks/useAuthUser';
import { userPropType } from '~/propTypes';

import BlockUser from './BlockUser';
import LoginAsUser from './LoginAsUser';
import Remove from './Remove';
import ResetPassword from './ResetPassword';
import ResetTwoFactor from './ResetTwoFactor';

const useStyles = makeStyles({
  root: {
    margin: '10px',
    height: '70px',
    width: '70px',
    fontSize: '24px',
  },
});

export default function Profile({ userData }) {
  const classes = useStyles();
  const { isAdmin, roles: authUserRoles } = useAuthUser();
  const hasLoginAsUserRole = authUserRoles.includes('trhub:login-as-user');

  const {
    id,
    uid,
    name,
    email,
    address,
    mobile,
    customerNumber,
    collectionPayee,
    initials,
    jokerProfile,
    roles,
    blocked,
    created,
    lastAccess,
    auctionParticipation,
    status,
  } = userData;

  const { pathname } = useLocation();

  const tableRows = [
    ['Namn', (name || '') + (collectionPayee ? ' (Samlingsbetalare)' : '')],
    ['Epost', email],
    ['Mobilnummer', mobile || <em>Uppgift saknas</em>],
    ['Kundnummer', customerNumber],
    ['Adress', <Address key={uid} address={address} />],
    ['Joker alias', jokerProfile?.alias || <em>Uppgift saknas</em>],
    ['Drupal ID', uid],
    [
      'Roller',
      <Box key="roles">
        {roles.map(role => (
          <Box whiteSpace="nowrap" key={role} fontSize="0.8125rem">
            {userRoles[role]}
          </Box>
        ))}
      </Box>,
    ],
    ['Skapad', <Time key={uid} date={new Date(created)} />],
    [
      'Auktionsstatus',
      auctionParticipation?.roles
        .map(role => auctionParticipationRole[role])
        .join(', '),
    ],
    [
      'Senast inloggad',
      lastAccess ? <Time key={uid} date={new Date(lastAccess)} /> : '',
    ],
    ['Status', status ? Types.userStatus[status] : ''],
  ];

  return (
    <>
      <TablePaper>
        <TableContainer>
          <Avatar classes={{ root: classes.root }}>{initials}</Avatar>
          {blocked && <Alert severity="warning">Användaren är spärrad</Alert>}
          <Table>
            <TableBody>
              {tableRows.map(([label, value]) => (
                <TableRow key={label}>
                  <TableCell component="th" scope="row">
                    {label}
                  </TableCell>
                  <TableCell align="right">{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TablePaper>
      <Box mt={2} display="flex" flexWrap="wrap" gap="10px">
        <Button
          component={NavLink}
          to={`${pathname}/edit`}
          variant="contained"
          color="secondary"
        >
          Redigera
        </Button>
        <BlockUser user={id} blocked={blocked} />
        {email && <ResetPassword userId={id} email={email} />}
        {hasLoginAsUserRole && <LoginAsUser userId={id} />}
        {isAdmin && roles?.length > 0 && <ResetTwoFactor user={id} />}
        <Remove user={id} />
      </Box>
    </>
  );
}

Profile.propTypes = {
  userData: userPropType.isRequired,
};
