import { useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { Switch, useParams, useRouteMatch } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';
import ErrorMessage from '~/components/ErrorMessage';

import query from './_GetTerms.gql';
import TermsEdit from './TermsEdit';

export default function Terms() {
  const { path } = useRouteMatch();
  const { id } = useParams();

  const { error, loading, data } = useQuery(query, {
    variables: { id },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (loading) {
    return <CircularProgress />;
  }

  const { getTerms: terms } = data;

  return (
    <>
      <Breadcrumb name={`${terms.name}, ${terms.version}`} />
      <Switch>
        <ErrorBoundRoute path={path} exact>
          <TermsEdit data={terms} />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
