import { useQuery } from '@apollo/client';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Link,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { formatMoney } from '@trmediaab/zebra-utils';
import PropTypes from 'prop-types';

import ErrorMessage from '~/components/ErrorMessage';
import TableList from '~/components/TableList';
import Time from '~/components/Time';
import usePagination from '~/hooks/usePagination';

import query from './_UnhandledPayments.gql';

const useStyles = makeStyles(() => ({
  tableRowSum: {
    background: 'linear-gradient(45deg, #2196F3 5%, #21CBF3 30%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
  },
}));

export default function UnhandledPaymentsListResults({ params }) {
  const { from, to, source, organization } = params;

  const classes = useStyles();

  const { page, pageSize, updatePage, updateRowSize } = usePagination(15);
  const { error, data } = useQuery(query, {
    variables: {
      page: page,
      limit: pageSize,
      filter: {
        from,
        to,
        source,
        organization,
      },
    },
  });

  const res = data?.list;

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!res) {
    return <CircularProgress />;
  }

  const headers = ['Inbetald', 'Belopp', 'Typ', 'Info'];

  return (
    <>
      <Box mb={3}>
        <Card>
          <CardContent>
            <Typography variant="h6" component="h2">
              Omatchade inbetalningar från Swedbank Pay (Totalt för perioden:{' '}
              {formatMoney(res.totalAmount)})
            </Typography>
          </CardContent>
          <TableList
            page={page}
            pageSize={pageSize}
            data={res}
            headers={headers}
            updatePage={updatePage}
            updateRowSize={updateRowSize}
          >
            {res?.items.length > 0 ? (
              res.items.map((item, index) => (
                <TableRow hover key={index}>
                  <TableCell className={classes.tableCellDate} width="30%">
                    <Time date={new Date(item.date)} />
                  </TableCell>
                  <TableCell width="30%">{formatMoney(item.amount)}</TableCell>
                  <TableCell>
                    {item.refund ? 'Återbetalning' : 'Inbetalning'}
                  </TableCell>
                  <TableCell>
                    <Link
                      href={`https://admin.payex.com/psp/beta/payments?reference=${item.payload.Reference}`}
                      target="_blank"
                    >
                      {item.payload.Description}
                    </Link>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  Inga omatchade inbetalningar finns för denna filtrering. Prova
                  att ange ett annat datumintervall.
                </TableCell>
              </TableRow>
            )}
          </TableList>
        </Card>
      </Box>
    </>
  );
}

UnhandledPaymentsListResults.propTypes = {
  params: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
    source: PropTypes.string,
    organization: PropTypes.string,
  }),
};
