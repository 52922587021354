import { Link, TableCell, TableRow } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { auctionParticipationRole } from 'trhub-utils';

import NoData from '~/components/NoData';
import TableList from '~/components/TableList';
import Time from '~/components/Time';
import { userPropType } from '~/propTypes';

export default function Auctions({ userData }) {
  const { auctionParticipation } = userData;

  const headers = [
    'Auktion',
    'Roll',
    'Deltog',
    'Kategori',
    'Vinnande bud',
    'Slutsumma',
    'Köpdatum',
  ];

  return (
    <TableList
      title={'Auktionsdeltaganden'}
      data={{ total: auctionParticipation?.auctions.length }}
      headers={headers}
    >
      {auctionParticipation?.auctions.length > 0 ? (
        auctionParticipation.auctions.map(auction => (
          <TableRow key={auction.id}>
            <TableCell>{auction.auction}</TableCell>
            <TableCell>
              {auctionParticipationRole[auction.participationRole]}
            </TableCell>
            <TableCell>
              <Time
                date={new Date(auction.participationDate)}
                format="yyyy-MM-dd HH:mm"
              />
            </TableCell>
            <TableCell>
              <Link
                component={RouterLink}
                to={`/products/${auction.category._id}`}
              >
                {auction.category.sku}
              </Link>
            </TableCell>
            <TableCell>{auction.buyerInfo.biddingAmount}</TableCell>
            <TableCell>{auction.buyerInfo.purchaseAmount}</TableCell>
            <TableCell>
              <Time
                date={new Date(auction.buyerInfo.purchaseDate)}
                format="yyyy-MM-dd HH:mm"
              />
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={headers.length}>
            <NoData text="Den här användaren har inte deltagit i några auktioner" />
          </TableCell>
        </TableRow>
      )}
    </TableList>
  );
}

Auctions.propTypes = {
  userData: userPropType.isRequired,
};
