import { Switch, useRouteMatch } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';

import Issue from './Issue';
import IssueAdd from './IssueAdd';
import IssueList from './IssueList';

export default function Issues() {
  const { path } = useRouteMatch();

  return (
    <>
      <Breadcrumb name="Ärenden" />
      <Switch>
        <ErrorBoundRoute path={path} exact>
          <IssueList />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/add`} exact>
          <IssueAdd />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/:issue`}>
          <Issue />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
