import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

import Events from '~/components/Events';
import OrderList from '~/components/OrderList';
import { orderBuilderPropType } from '~/propTypes';

import FailedOrders from './FailedOrders';
import Information from './Information';
import MissingUsers from './MissingUsers';
import StatusInfo from './StatusInfo';
import UserList from './UserList';

export default function OrderBuilderDetail({ orderBuilderData, refetch }) {
  const {
    eventLog,
    id,
    failedOrderByUsers,
    missingUsers,
    blockedUsers,
    subExistsForUsers,
    status,
  } = orderBuilderData.getOrderBuilder;

  return (
    <Grid container spacing={3}>
      <Grid item md={12}>
        <StatusInfo />
      </Grid>

      <Grid item xs={12} md={6}>
        <Information
          orderBuilderData={orderBuilderData.getOrderBuilder}
          refetch={refetch}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Events items={eventLog} />
      </Grid>

      <Grid item xs={12}>
        <OrderList
          size={5}
          filter={{ orderBuilder: id }}
          orderBuilderLabel
          showUser
        />
      </Grid>

      <Grid item xs={12}>
        <FailedOrders
          orderBuilder={id}
          failedOrderByUsers={failedOrderByUsers}
          status={status}
        />
      </Grid>

      <Grid item xs={12}>
        <MissingUsers missingUsers={missingUsers} />
      </Grid>

      <Grid item xs={12}>
        <UserList users={subExistsForUsers} type={'subscriptionExists'} />
      </Grid>

      <Grid item xs={12}>
        <UserList users={blockedUsers} type={'blocked'} />
      </Grid>
    </Grid>
  );
}

OrderBuilderDetail.propTypes = {
  orderBuilderData: PropTypes.shape({
    getOrderBuilder: orderBuilderPropType,
  }),
  refetch: PropTypes.func.isRequired,
};
