import { List as ListIcon } from '@mui/icons-material';
import { Button, TableCell, TableRow } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { sites } from 'trhub-utils';

import NoData from '~/components/NoData';
import TableList from '~/components/TableList';
import Time from '~/components/Time';

export default function TicketUsage({ ticket }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleClickOpen} variant="contained">
        <ListIcon />
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Klippkortsanvändning - {sites[ticket.site]}{' '}
          {ticket.betType.join(', ')}
        </DialogTitle>
        <DialogContent>
          <TableList data={{}} headers={['Datum', 'Speltips']}>
            {ticket.usage.length > 0 ? (
              ticket.usage.map(product => (
                <TableRow key={product.id}>
                  <TableCell>
                    <Time date={new Date(product.roundDate)} />
                  </TableCell>
                  <TableCell>{product.name}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={2}>
                  <NoData text="Det här klippkortet är inte använt" />
                </TableCell>
              </TableRow>
            )}
          </TableList>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Stäng
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

TicketUsage.propTypes = {
  ticket: PropTypes.object.isRequired,
};
