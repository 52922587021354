import { useQuery } from '@apollo/client';
import { Box, CircularProgress } from '@mui/material';
import { useFormikContext } from 'formik';
import { sites } from 'trhub-utils';

import ErrorMessage from '~/components/ErrorMessage';
import Select from '~/components/Select';

import query from './_GetActiveCampaigns.gql';

export default function PaywallCampaigns() {
  const { data, loading, error } = useQuery(query);

  const campaigns = data?.campaigns || [];
  const { values, setFieldValue } = useFormikContext();

  if (loading) {
    return (
      <Box my={3}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {error && <ErrorMessage error={error} />}
      {Object.entries(sites).map(([site, label]) => (
        <Box key={site} pt={1}>
          <Select
            label={label}
            value={values.paywallCampaigns?.[site] || ''}
            onChange={e => {
              setFieldValue(`paywallCampaigns.${site}`, e.target.value);
            }}
            options={[
              { value: '', label: 'Ingen' },
              ...campaigns
                .filter(c => c.site === site)
                .map(campaign => ({
                  value: campaign.id,
                  label: campaign.name,
                })),
            ]}
          />
        </Box>
      ))}
    </>
  );
}
