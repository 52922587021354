import {
  CloudUpload,
  Delete,
  InsertDriveFile,
  PictureAsPdf,
} from '@mui/icons-material';
import { Box, Button, ButtonGroup } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import ErrorMessage from '~/components/ErrorMessage';

export default function UploadFile({
  url,
  refetch,
  endpoint,
  type,
  href,
  disableRemoval,
}) {
  const [error, setError] = useState({
    message: '',
  });

  const handleFileInputChange = useCallback(
    async event => {
      const data = new FormData();
      data.append('file', event.target.files[0]);

      await fetch(endpoint, {
        method: 'POST',
        body: data,
      })
        .then(async res => {
          if (!res.ok) {
            setError({ message: `Uppladdning av ${type} misslyckades.` });
          }
          //Re-fetch getProduct query
          if (!url) {
            refetch();
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    [endpoint, refetch, type, url],
  );

  const handleRemoveFile = async () => {
    if (!window.confirm(`Är du säker på att du vill radera ${type}:en?`)) {
      return;
    }
    await fetch(endpoint, {
      method: 'DELETE',
    }).then(async res => {
      if (!res.ok) {
        setError({ message: `Borttagning av ${type} misslyckades.` });
      }
      //Re-fetch getProduct query
      refetch();
    });
  };

  return (
    <Box display={error.message && 'flex'}>
      {error.message && <ErrorMessage error={error} />}
      {url ? (
        <ButtonGroup variant="outlined">
          <Button href={href} target="_blank" color="primary">
            {type === 'pdf' ? <PictureAsPdf /> : <InsertDriveFile />}
          </Button>
          {!disableRemoval && (
            <Button onClick={handleRemoveFile}>
              <Delete />
            </Button>
          )}
        </ButtonGroup>
      ) : (
        <Button variant="outlined" color="secondary" component="label">
          <CloudUpload />
          <input
            type="file"
            accept={`.${type}`}
            hidden
            onChange={handleFileInputChange}
          />
        </Button>
      )}
    </Box>
  );
}

UploadFile.propTypes = {
  endpoint: PropTypes.string.isRequired,
  url: PropTypes.string,
  refetch: PropTypes.func,
  type: PropTypes.string,
  href: PropTypes.string,
  disableRemoval: PropTypes.bool,
};
