import { useQuery } from '@apollo/client';
import { CircularProgress, Link, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import ErrorMessage from '~/components/ErrorMessage';
import TableList from '~/components/TableList';
import usePagination from '~/hooks/usePagination';

import query from './_GetComplaintReport.gql';

export default function ComplaintListResult({ params }) {
  const { page, pageSize, updatePage, updateRowSize } = usePagination(15);

  const { error, data } = useQuery(query, {
    variables: {
      distributor: params.distributor,
      from: params.from,
      to: params.to,
      page: page,
      limit: pageSize,
    },
  });

  const res = data?.getComplaintReport;

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!res) {
    return <CircularProgress />;
  }

  const headers = [
    'Utgåva',
    'Tidningen kom inte',
    'Tidningen var skadad',
    'Tidningen kom sent',
    'Tidningen var ej nedstoppad i brevlådan',
    'Fel uppgifter på tidningen',
  ];

  return (
    <>
      <TableList
        page={page}
        pageSize={pageSize}
        data={res}
        headers={headers}
        updatePage={updatePage}
        updateRowSize={updateRowSize}
      >
        {res?.complaintReport.length > 0 ? (
          res.complaintReport.map((row, index) => (
            <TableRow hover key={index}>
              <TableCell>
                {' '}
                <Link component={RouterLink} to={`/editions/${row.edition.id}`}>
                  {row.edition.editionLabel}
                </Link>
              </TableCell>
              <TableCell align="center">
                {row.reasons.find(elem => elem.reason === 1)?.count || 0}
              </TableCell>
              <TableCell align="center">
                {row.reasons.reduce(
                  (acc, elem) => (elem.reason >= 20 ? acc + elem.count : 0),
                  0,
                )}
              </TableCell>
              <TableCell align="center">
                {row.reasons.find(elem => elem.reason === 4)?.count || 0}
              </TableCell>
              <TableCell align="center">
                {row.reasons.find(elem => elem.reason === 5)?.count || 0}
              </TableCell>
              <TableCell align="center">
                {row.reasons.find(elem => elem.reason === 7)?.count || 0}
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={5} align="center">
              Inga utgåvor finns för denna filtrering. Prova att ange ett annat
              datumintervall.
            </TableCell>
          </TableRow>
        )}
      </TableList>
    </>
  );
}

ComplaintListResult.propTypes = {
  params: PropTypes.shape({
    distributor: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }).isRequired,
};
