import { useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { Switch, useParams, useRouteMatch } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';
import ErrorMessage from '~/components/ErrorMessage';

import query from './_GetSubscription.gql';
import SubscriptionCancel from './SubscriptionCancel';
import SubscriptionChangeDeliveryMethod from './SubscriptionChangeDeliveryMethod';
import SubscriptionDetail from './SubscriptionDetail';
import SubscriptionExtend from './SubscriptionExtend';
import SubscriptionPause from './SubscriptionPause';
import SubscriptionReactivate from './SubscriptionReactivate';

export default function Subscription() {
  const { path } = useRouteMatch();
  const { subscription } = useParams();
  const { error, loading, data } = useQuery(query, {
    variables: { subscription },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Breadcrumb name={data.getSubscription.user.label} />
      <Switch>
        <ErrorBoundRoute path={path} exact>
          <SubscriptionDetail subscriptionData={data} />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/pause`} exact>
          <SubscriptionPause subscriptionData={data} />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/extend`} exact>
          <SubscriptionExtend subscriptionData={data} />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/cancel`} exact>
          <SubscriptionCancel subscriptionData={data} />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/reactivate`} exact>
          <SubscriptionReactivate subscriptionData={data} />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/change-delivery-method`} exact>
          <SubscriptionChangeDeliveryMethod subscriptionData={data} />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
