import { useMutation } from '@apollo/client';
import { PictureAsPdf } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { formatMoney } from '@trmediaab/zebra-utils';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import {
  Link as RouterLink,
  NavLink,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { orderBuilderStatus } from 'trhub-utils';

import ErrorMessage from '~/components/ErrorMessage';
import TablePaper from '~/components/TablePaper';
import Time from '~/components/Time';
import UploadFile from '~/components/UploadFile';
import useAuthUser from '~/hooks/useAuthUser';
import { orderBuilderPropType } from '~/propTypes';

import RemoveOrderBuilder from './_RemoveOrderBuilder.gql';
import SendOrderBuilder from './_SendOrderBuilder.gql';

export default function Information({ orderBuilderData, refetch }) {
  const { pathname } = useLocation();

  const {
    id,
    name,
    price,
    product,
    status,
    created,
    csvUrl,
    pdfUrl,
    totalUsers,
    processed,
    paidOrders,
    missingUsersCount,
    subExistsForUsersCount,
    blockedUsersCount,
    failedOrderByUsersCount,
    sendDate,
  } = orderBuilderData;

  const history = useHistory();

  const [removeAction, { error: removeError, loading: removing }] =
    useMutation(RemoveOrderBuilder);

  const removeOrderBuilder = useCallback(async () => {
    await removeAction({
      variables: {
        id,
      },
    });
    history.push('/order-builders');
  }, [removeAction, history, id]);

  const [action, { loading, error: sentError }] = useMutation(SendOrderBuilder);

  const sendOrderBuilder = useCallback(() => {
    action({
      variables: {
        id: id,
      },
    });
  }, [action, id]);

  const { isAdmin } = useAuthUser();

  const tableRows = [
    ['Namn', name],
    ['Pris', formatMoney(price)],
    [
      'Product',
      <Link
        key={product.id}
        component={RouterLink}
        to={`/products/${product.id}`}
      >
        {product.name}
      </Link>,
    ],
    [
      'Status',
      <Chip
        key={id}
        color="primary"
        label={
          status === 'in_progress'
            ? `${orderBuilderStatus[status]} (Kan ej avbrytas)`
            : orderBuilderStatus[status]
        }
        size="small"
      />,
    ],
    [
      'Skapad',
      <Time key={id} date={new Date(created)} format="yyyy-MM-dd HH:mm" />,
    ],
    [
      'Utskicksdatum',
      sendDate ? (
        <Time key={id} date={new Date(sendDate)} format="yyyy-MM-dd HH:mm" />
      ) : (
        'Ej utskickad'
      ),
    ],
    [
      'CSV användarlista',
      <UploadFile
        key="csv"
        endpoint={`/api/order_builder_file/csv/${id}`}
        url={csvUrl}
        refetch={refetch}
        type="csv"
        disableRemoval={status !== 'draft'}
      />,
    ],
    [
      'PDF huvudförnyelse',
      <UploadFile
        key="pdf"
        endpoint={`/api/order_builder_file/pdf/${id}`}
        url={pdfUrl}
        href={pdfUrl}
        refetch={refetch}
        type="pdf"
        disableRemoval={status !== 'draft'}
      />,
    ],
    ['Användare i csv-fil', totalUsers || 0],
    ['Skapade fakturor', processed || 0],
    ['Betalda fakturor', paidOrders || 0],
    ['Användare saknas', missingUsersCount || 0],
    ['Prenumeration finns redan för användare', subExistsForUsersCount || 0],
    ['Blockerade användare', blockedUsersCount || 0],
    ['Misslyckade utskick', failedOrderByUsersCount || 0],
  ];

  return (
    <>
      <TablePaper>
        <TableContainer>
          <Table>
            <TableBody>
              {tableRows.map(([label, value]) => (
                <TableRow key={label}>
                  <TableCell component="th" scope="row">
                    {label}
                  </TableCell>
                  <TableCell align="right">{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TablePaper>
      {removeError && (
        <Box my={2}>
          <ErrorMessage error={removeError} />
        </Box>
      )}
      {isAdmin && (
        <Box mt={2} display="flex">
          <Box>
            <Button
              component={NavLink}
              to={`${pathname}/edit`}
              variant="contained"
              color="secondary"
              disabled={status !== 'draft'}
            >
              Redigera
            </Button>
            <Button
              onClick={removeOrderBuilder}
              variant="contained"
              disabled={status !== 'draft' || removing}
              sx={theme => ({
                marginLeft: '10px',
                backgroundColor: theme.palette.error.main,
                color: theme.palette.text.main,
                '&:hover': {
                  background: theme.palette.error.dark,
                },
              })}
            >
              {removing ? 'Raderar...' : 'Radera'}
            </Button>
          </Box>
          <Box ml={2}>
            <Button
              variant="contained"
              color="secondary"
              disabled={status !== 'draft' || !csvUrl || !pdfUrl || loading}
              onClick={sendOrderBuilder}
            >
              Skicka
            </Button>
          </Box>
          <Box ml={2}>
            <Button
              color="primary"
              variant="contained"
              startIcon={<PictureAsPdf />}
              component="a"
              target="_blank"
              href={`/api/preview_order_builder?orderBuilder=${id}`}
              rel="noreferrer"
            >
              Förhandsgranska
            </Button>
          </Box>
        </Box>
      )}
      {sentError && (
        <Box mt={2}>
          <ErrorMessage error={sentError} />
        </Box>
      )}
    </>
  );
}

Information.propTypes = {
  orderBuilderData: orderBuilderPropType,
  refetch: PropTypes.func.isRequired,
};
