import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useCallback } from 'react';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorMessage from '~/components/ErrorMessage';

import mutation from './_EditCampaignSettings.gql';
import query from './_GetCampaignSettings.gql';
import CancellationOfferCampaigns from './CancellationOfferCampaigns';
import PaywallCampaigns from './PaywallCampaigns';

export default function CampaignSettings() {
  const { data, loading } = useQuery(query);

  const [action, { error }] = useMutation(mutation);

  const onSubmit = useCallback(
    async (values, { setSubmitting }) => {
      await action({
        variables: {
          input: values,
        },
      });

      setSubmitting(false);
    },
    [action],
  );

  if (loading || !data) {
    return <CircularProgress />;
  }

  return (
    <>
      <Breadcrumb name="Inställningar" />
      <Formik
        initialValues={data.settings}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ submitForm, isSubmitting }) => (
          <Form
            css={{
              '.MuiFormControl-root': {
                width: '100%',
              },
            }}
          >
            <Paper>
              <Box display="flex" justifyContent="space-between">
                <Box flex={1} p={3}>
                  <Typography component="h2" variant="h6" color="primary">
                    Kampanjer i uppsägningserbjudande
                  </Typography>
                  <CancellationOfferCampaigns />
                </Box>
                <Box flex={1} p={3}>
                  <Typography component="h2" variant="h6" color="primary">
                    Kampanjer i betalvägg
                  </Typography>
                  <PaywallCampaigns />
                </Box>
              </Box>
              {error && <ErrorMessage error={error} />}
              <Box p={1}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  {isSubmitting ? 'Sparar...' : 'Spara'}
                </Button>
              </Box>
            </Paper>
          </Form>
        )}
      </Formik>
    </>
  );
}
