import { GetApp } from '@mui/icons-material';
import { Button, Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getYear } from 'date-fns';
import { Switch, useRouteMatch } from 'react-router-dom';
import { orderSearchStartYear } from 'trhub-utils';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';
import SelectField from '~/components/Select';
import useQueryParams from '~/hooks/useQueryParams';

const useStyles = makeStyles(theme => ({
  card: {
    minWidth: 275,
    marginBottom: theme.spacing(3),
  },
}));
const initialValues = {
  year: getYear(new Date()).toString(),
};

export default function ExportPage() {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const { state, handleChange } = useQueryParams(initialValues);
  const urlParams = new URLSearchParams();

  if (state.year) {
    urlParams.set('year', state.year);
  }
  const yearOptions = [];
  for (let year = orderSearchStartYear; year <= getYear(new Date()); year++) {
    yearOptions.push({ value: year.toString(), label: year.toString() });
  }
  return (
    <>
      <Breadcrumb name="Export - ekonomi" />
      <Switch>
        <ErrorBoundRoute path={path} exact>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h5" component="h2">
                Export SAF-T
              </Typography>
              <Typography
                variant="body2"
                component="p"
                sx={theme => ({
                  marginTop: theme.spacing(2),
                })}
              >
                <>
                  <a
                    href="https://en.wikipedia.org/wiki/SAF-T"
                    target="_blank"
                    rel="noopener"
                  >
                    SAF-T
                  </a>{' '}
                  är en internationell standard för rapportering av
                  redovisningsdata till skattemyndigheter. Från och med den 1
                  januari 2020 införs en obligatorisk SAF-T-rapportering i
                  Norge. Här kan man exportera SAF-T för Jokersystemet Norge.{' '}
                </>
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={theme => ({
                  marginTop: theme.spacing(2),
                })}
              >
                <SelectField
                  label="År"
                  value={state.year.toString()}
                  onChange={e => handleChange('year', e.target.value)}
                  className={classes.selectField}
                  options={yearOptions}
                />
              </Typography>
              <Typography
                variant="body2"
                component="p"
                sx={theme => ({
                  marginTop: theme.spacing(2),
                })}
              >
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<GetApp />}
                  component="a"
                  target="_blank"
                  href={`/api/export/economy_saft?${urlParams.toString()}`}
                  rel="noreferrer"
                >
                  Exportera
                </Button>
              </Typography>
            </CardContent>
          </Card>
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
