import { useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { Switch, useParams, useRouteMatch } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';
import ErrorMessage from '~/components/ErrorMessage';

import query from './_GetCampaign.gql';
import CampaignDetail from './CampaignDetail';
import CampaignEdit from './CampaignEdit';

export default function Campaign() {
  const { path } = useRouteMatch();
  const { campaign } = useParams();
  const { error, loading, data } = useQuery(query, {
    variables: { campaign },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Breadcrumb name={data.getCampaign.name} />
      <Switch>
        <ErrorBoundRoute path={path} exact>
          <CampaignDetail campaignData={data} />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/edit`} exact>
          <CampaignEdit campaignData={data} />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
