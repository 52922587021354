import { Add } from '@mui/icons-material';
import { Box, Button, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link, useLocation } from 'react-router-dom';

import useAuthUser from '~/hooks/useAuthUser';
import useQueryParams from '~/hooks/useQueryParams';
import shallowEqual from '~/utils/shallowEqual';

import OrderBuilderListResults from './OrderBuilderListResult';

const useStyles = makeStyles({
  textField: {
    maxWidth: 150,
    minWidth: 125,
    marginTop: 10,
  },
});

const initialValues = {
  search: '',
};

export default function OrderList() {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { isAdmin } = useAuthUser();

  const { params, state, clear, handleChange } = useQueryParams(initialValues);

  return (
    <>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Box
          maxWidth="100%"
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          columnGap="10px"
          marginTop="-10px"
          marginBottom={2}
        >
          <TextField
            className={classes.textField}
            label="Sök"
            variant="outlined"
            value={state.search.toString()}
            onChange={e => handleChange('search', e.target.value)}
          />

          {!shallowEqual(initialValues, state) && (
            <Button
              color="primary"
              sx={{
                textTransform: 'none',
              }}
              onClick={clear}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>

        <Box mb={2} display="flex" justifyContent="space-between">
          {isAdmin && (
            <Button
              component={Link}
              to={`${pathname}/add`}
              color="primary"
              variant="contained"
              startIcon={<Add />}
            >
              Lägg till
            </Button>
          )}
        </Box>
      </Box>

      <Box>
        <OrderBuilderListResults params={params} />
      </Box>
    </>
  );
}
