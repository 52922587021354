import MarkdownIt from 'markdown-it';
import MarkdownItMarkPlugin from 'markdown-it-mark';

const parser = new MarkdownIt({ breaks: true, linkify: true });
// Parses ``==text==`` to <mark>text</mark>
parser.use(MarkdownItMarkPlugin);

export default function parse(markdown) {
  if (markdown == null) {
    return null;
  }
  return parser.render(markdown);
}
