import { Check } from '@mui/icons-material';
import { Avatar, Link } from '@mui/material';
import { green } from '@mui/material/colors';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { termsStatus } from 'trhub-utils';

import DataGrid from '~/components/DataGrid';
import Time from '~/components/Time';

import query from './_GetTermsList.gql';

const columns = [
  {
    field: 'name',
    flex: 2,
    headerName: 'Avtal',
    sortable: false,
    renderCell: params => (
      <Link component={RouterLink} to={`/settings/terms/${params.row.id}`}>
        {params.value}
      </Link>
    ),
  },
  {
    field: 'version',
    flex: 1,
    headerName: 'Version',
    sortable: false,
  },
  {
    field: 'status',
    flex: 1,
    headerName: 'Status',
    sortable: false,
    renderCell: params => (
      <>
        {params.value === 'published' && (
          <Avatar
            css={{
              background: green[500],
              height: '16px',
              width: '16px',
              display: 'inline-flex',
              marginRight: '8px',
            }}
          >
            <Check
              css={{
                height: '12px',
                width: '12px',
              }}
            />
          </Avatar>
        )}
        {termsStatus[params.value]}
      </>
    ),
  },

  {
    field: 'published',
    flex: 1,
    headerName: 'Publicerat',
    renderCell: params =>
      params.value && (
        <Time date={new Date(params.value)} format="yyyy-MM-dd HH:mm" />
      ),
  },
];

export default function TermsListResults({ params }) {
  const { status } = params;

  return (
    <DataGrid
      columns={columns}
      query={query}
      defaultSort="-created"
      filter={{
        status,
      }}
    />
  );
}

TermsListResults.propTypes = {
  params: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }).isRequired,
};
