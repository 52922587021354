import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { formatMoney } from '@trmediaab/zebra-utils';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { campaignTypes, sites } from 'trhub-utils';

import ErrorMessage from '~/components/ErrorMessage';
import TablePaper from '~/components/TablePaper';
import Time from '~/components/Time';
import useAuthUser from '~/hooks/useAuthUser';
import { campaignPropType } from '~/propTypes';

import CopyToClipboard from '../../components/CopyToClipboard';
import DeleteCampaign from './_DeleteCampaign.gql';
import query from './_GetCampaignLandingPages.gql';

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(3),
  },
}));
export default function CampaignDetail({ campaignData }) {
  const { pathname } = useLocation();
  const classes = useStyles();

  const {
    id,
    name,
    code,
    type,
    price,
    site,
    publicUrl,
    duration,
    expireDate,
    products,
    firstPurchaseOnProductOnly,
    offerUponCancellation,
    limit,
    endDate,
    endSubscriptionAfterDuration,
  } = campaignData.getCampaign;

  const {
    loading: landingPageLoading,
    error: landingPageError,
    data,
  } = useQuery(query, {
    variables: { campaign: id },
  });

  const landingPages = data?.list?.landingPages ?? [];

  const { isAdmin } = useAuthUser();

  const history = useHistory();

  const [deleteAction, { error: deleteError, loading: deleting }] =
    useMutation(DeleteCampaign);

  const deleteCampaign = useCallback(async () => {
    await deleteAction({
      variables: {
        id,
      },
    });
    history.push('/campaigns');
  }, [deleteAction, history, id]);

  const tableRows = [
    ['Namn', name],
    ['Rabattkod', code],
    ['Rabattyp', campaignTypes[type]],
    ['Sajt', sites[site]],
    [
      'Kampanjen ersätter det ordinarie priset i checkout för produkten och tillämpas endast på kundens första köp av produkten',
      <FormControlLabel
        key={id}
        control={<Checkbox checked={firstPurchaseOnProductOnly ?? false} />}
      />,
    ],
    [
      'Kampanjen visas ut när kunden avslutar sin prenumeration',
      <FormControlLabel
        key={id}
        control={<Checkbox checked={offerUponCancellation ?? false} />}
      />,
    ],
  ];

  expireDate &&
    tableRows.push([
      'Sista datumet för att nyttja kampanjen',
      <Time key={code} date={new Date(expireDate)} />,
    ]);

  endDate &&
    tableRows.push([
      'Slutdatumet som kampanjen gäller för prenumerationen (endast för migrerings-kampanjer)',
      <Time key={code} date={new Date(endDate)} />,
    ]);

  duration &&
    tableRows.push([
      'Kampanjlängd',
      duration === 1 ? `${duration} månad` : `${duration} månader`,
    ]);

  products.some(item => item.product.type === 'subscription') &&
    tableRows.push([
      'Avsluta kopplad prenumeration efter att kampanjlängden har passerat',
      <FormControlLabel
        key={code}
        control={<Checkbox checked={endSubscriptionAfterDuration ?? false} />}
      />,
    ]);

  limit && tableRows.push(['Begränsat nyttjande', `${limit}`]);

  for (const [i, obj] of products.entries()) {
    tableRows.push(
      [
        `Produkt ${i + 1}`,
        `${obj.product.name} (ord.pris ${formatMoney(obj.product.price)})`,
      ],
      [`Inkomstfördelning för ${obj.product.name}`, `${obj.percentage} %`],
    );
  }

  tableRows.push([
    type === 'monthlyDiscount'
      ? 'Pris att betala per månad'
      : 'Pris att betala direkt',
    `${formatMoney(price)}`,
  ]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <TablePaper>
          <TableContainer>
            <Table>
              <TableBody>
                {tableRows.map(([label, value]) => (
                  <TableRow key={label}>
                    <TableCell component="th" scope="row">
                      {label}
                    </TableCell>
                    <TableCell align="right">{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TablePaper>
        {deleteError && (
          <Box my={2}>
            <ErrorMessage error={deleteError} />
          </Box>
        )}
        {isAdmin && (
          <Box mt={2}>
            <Button
              component={NavLink}
              to={`${pathname}/edit`}
              variant="contained"
              color="secondary"
            >
              Redigera
            </Button>

            <Button
              onClick={deleteCampaign}
              variant="contained"
              disabled={deleting}
              sx={theme => ({
                marginLeft: '10px',
                backgroundColor: theme.palette.error.main,
                color: theme.palette.text.main,
                '&:hover': {
                  background: theme.palette.error.dark,
                },
              })}
            >
              {deleting ? 'Raderar...' : 'Radera'}
            </Button>
          </Box>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        {publicUrl != null && (
          <Card className={classes.card}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Publik adress till kampanjen
              </Typography>
              <Box
                css={`
                  word-break: break-all;
                `}
              >
                <a href={publicUrl} target="_blank" rel="noreferrer">
                  {publicUrl}
                </a>
              </Box>
            </CardContent>
            <CardActions>
              <CopyToClipboard value={publicUrl} />
            </CardActions>
          </Card>
        )}
        {landingPageError && <ErrorMessage error={landingPageError} />}
        {landingPageLoading && <CircularProgress />}
        {landingPages.length > 0 && (
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                {`Länk till ${
                  landingPages.length > 1
                    ? 'landningssidorna'
                    : 'landningssidan'
                } för kampanjen i TR Content`}
              </Typography>
              {landingPages.map(landingPage => (
                <Box
                  key={landingPage.id}
                  css={`
                    word-break: break-all;
                  `}
                >
                  <a
                    href={`${data.list.uri}/${landingPage.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {landingPage.name}
                  </a>
                </Box>
              ))}
            </CardContent>
          </Card>
        )}
      </Grid>
    </Grid>
  );
}

CampaignDetail.propTypes = {
  campaignData: PropTypes.shape({
    getCampaign: campaignPropType,
  }).isRequired,
};
