import { Help } from '@mui/icons-material';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import TablePaper from '~/components/TablePaper';

export default function TableList({
  title,
  page,
  pageSize,
  data,
  headers,
  helpTexts,
  children,
  updatePage,
  updateRowSize,
  selectedRows,
  handleSelectAllRows,
  checkbox,
  button,
}) {
  return (
    <TablePaper title={title} button={button}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {checkbox && (
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedRows.length === pageSize}
                    color="primary"
                    indeterminate={
                      selectedRows.length > 0 && selectedRows.length < pageSize
                    }
                    onChange={event => {
                      const { checked } = event.target;
                      handleSelectAllRows(checked, data.users);
                    }}
                  />
                </TableCell>
              )}
              {headers.map((header, index) => (
                <TableCell key={index}>
                  <Typography variant="inherit" noWrap>
                    {header}
                    {helpTexts && helpTexts[index] && (
                      <Tooltip title={helpTexts[index]}>
                        <Help style={{ fontSize: '1em' }} />
                      </Tooltip>
                    )}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
      {pageSize && (
        <TablePagination
          component="div"
          count={data.total || 0}
          onPageChange={(_, e) => updatePage(e)}
          onRowsPerPageChange={e => updateRowSize(e.target.value)}
          page={page - 1}
          rowsPerPage={pageSize}
          rowsPerPageOptions={[]}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} av ${count > 10000 ? `fler än ${count}` : count}`
          }
        />
      )}
    </TablePaper>
  );
}

TableList.propTypes = {
  title: PropTypes.string,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  data: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired,
  helpTexts: PropTypes.array,
  children: PropTypes.node,
  updatePage: PropTypes.func,
  updateRowSize: PropTypes.func,
  selectedRows: PropTypes.array,
  handleSelectAllRows: PropTypes.func,
  checkbox: PropTypes.bool,
  button: PropTypes.node,
};
