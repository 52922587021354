import { useApolloClient, useMutation } from '@apollo/client';
import { Delete as DeleteIcon } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  LinearProgress,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { formatMoney } from '@trmediaab/zebra-utils';
import { Field, FieldArray, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { billectaPaymentMethods, lineItemTypes } from 'trhub-utils';
import * as yup from 'yup';

import { Breadcrumb } from '~/components/Breadcrumbs';
import { NumberField } from '~/components/NumberField';
import TablePaper from '~/components/TablePaper';
import { orderPropType } from '~/propTypes';
import toOptions from '~/utils/toOptions';

import mutation from './_RefundOrder.gql';

const validationSchema = yup.object().shape({
  receiverName: yup.string().when('paymentMethod', {
    is: value => ['notification', 'autogiro'].includes(value),
    then: yup.string().required('Mottagarens namn måste anges.'),
  }),

  bankgiroNo: yup.string().when('paymentMethod', {
    is: value => ['notification', 'autogiro'].includes(value),
    then: yup.string().when('receivingPaymentMethod', {
      is: value => value === 'BankGiro',
      then: yup.string().required('Bankgironummer måste anges.'),
    }),
  }),

  plusgiroNo: yup.string().when('paymentMethod', {
    is: value => ['notification', 'autogiro'].includes(value),
    then: yup.string().when('receivingPaymentMethod', {
      is: value => value === 'PlusGiro',
      then: yup.string().required('Plusgironummer måste anges.'),
    }),
  }),

  clearingNo: yup.string().when('paymentMethod', {
    is: value => ['notification', 'autogiro'].includes(value),
    then: yup.string().when('receivingPaymentMethod', {
      is: value => value === 'BankAccount',
      then: yup.string().required('Clearingnummer måste anges.'),
    }),
  }),

  accountNo: yup.string().when('paymentMethod', {
    is: value => ['notification', 'autogiro'].includes(value),
    then: yup.string().when('receivingPaymentMethod', {
      is: value => value === 'BankAccount',
      then: yup.string().required('Kontonummer måste anges.'),
    }),
  }),
});

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    marginBottom: theme.spacing(3),
  },
  refundField: {
    maxWidth: 80,
  },
}));
const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function OrderRefund({ data }) {
  const classes = useStyles();

  const apollo = useApolloClient();

  const history = useHistory();

  const [error, setError] = useState('');

  const { order } = useParams();

  const { totalPrice, paymentMethod, user, netPrice, totalVAT } = data.getOrder;
  let { lineItems } = data.getOrder;

  lineItems = lineItems.map(lineItem => ({
    ...lineItem,
    refund:
      (lineItem.price * (lineItem.quantity || 1) - (lineItem.discount || 0)) /
      100,
  }));

  const [action] = useMutation(mutation, {
    onCompleted: async () => {
      // Ensure all lists are refreshed
      await apollo.resetStore();
    },
  });

  return (
    <>
      <Breadcrumb name="Återbetalning" />
      {error.length > 0 && (
        <Box mb={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Återbetalning
          </Typography>
          <Typography
            variant="body2"
            component="p"
            sx={theme => ({
              marginTop: theme.spacing(2),
            })}
          >
            {['notification', 'autogiro'].includes(paymentMethod) && (
              <>
                Återbetalningen registreras som en kreditorder, men behöver
                attesteras av två behöriga attestanter.
              </>
            )}
            {['card', 'swish', 'payexInvoice'].includes(paymentMethod) && (
              <>
                Återbetalningen sker direkt via SwedbankPay och registreras som
                en kreditorder.
              </>
            )}
          </Typography>
        </CardContent>
      </Card>

      <Formik
        initialValues={{
          paymentMethod: paymentMethod,
          receivingPaymentMethod: 'BankGiro',
          receiverName: user?.name,
          lineItems: lineItems,
          bankgiroNo: '',
          plusgiroNo: '',
          clearingNo: '',
          accountNo: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          const lineItems = values.lineItems.map(lineItem => ({
            refund: Math.round(0 - lineItem.refund * 100),
            vat: lineItem.vat,
            type: lineItem.type,
            ...(lineItem.product && { productId: lineItem.product.id }),
          }));
          const response = await action({
            variables: {
              input: {
                order,
                ...values,
                lineItems,
              },
            },
          }).catch(responseError => setError(responseError.message));
          setSubmitting(false);
          if (response) {
            const creditOrderId = response.data.addRefund;
            history.push(`/order/${creditOrderId}`);
          }
        }}
        validateOnBlur={false}
      >
        {({ submitForm, isSubmitting, values }) => (
          <Form
            sx={theme => ({
              '> * + *': {
                marginTop: theme.spacing(2),
              },
              '> *': {
                width: '100%',
              },
              '> .MuiFormControl-root': {
                backgroundColor: theme.palette.background.default,
              },
            })}
          >
            <TablePaper title="Varor">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Vara</TableCell>
                      <TableCell align="right">Antal</TableCell>
                      <TableCell align="right">Á pris</TableCell>
                      <TableCell align="right">Moms</TableCell>
                      <TableCell align="right">Rabatt</TableCell>
                      <TableCell align="right">Återbetalas</TableCell>
                      <TableCell align="right">Summa</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <FieldArray
                      name="lineItems"
                      render={arrayHelpers => (
                        <>
                          {values.lineItems.map((lineItem, index) => (
                            <StyledTableRow key={index}>
                              <TableCell component="th" scope="row">
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => arrayHelpers.remove(index)}
                                  size="large"
                                >
                                  <DeleteIcon />
                                </IconButton>
                                {lineItem.product
                                  ? lineItem.product?.name
                                  : lineItemTypes[lineItem.type]}{' '}
                                {lineItem.label !== lineItem.product?.name
                                  ? lineItem.label
                                  : ''}
                              </TableCell>
                              <TableCell align="right">
                                {lineItem.quantity || '1'}
                              </TableCell>
                              <TableCell align="right">
                                {formatMoney(lineItem.price)}
                              </TableCell>
                              <TableCell align="right">
                                {lineItem.vat}%
                              </TableCell>
                              <TableCell align="right">
                                {lineItem.discount
                                  ? formatMoney(lineItem.discount)
                                  : ''}
                              </TableCell>
                              <TableCell align="right">
                                <Field
                                  className={classes.refundField}
                                  component={TextField}
                                  width="50%"
                                  variant="outlined"
                                  inputMode="numeric"
                                  pattern="[0-9]*"
                                  name={`lineItems[${index}].refund`}
                                />
                              </TableCell>
                              <TableCell align="right">
                                {formatMoney(
                                  lineItem.price * (lineItem.quantity || 1) -
                                    (lineItem.discount || 0),
                                )}
                              </TableCell>
                            </StyledTableRow>
                          ))}
                        </>
                      )}
                    />
                    <TableRow>
                      <TableCell
                        rowSpan={4}
                        css={`
                          border: 0;
                        `}
                      />
                      <TableCell align="right">
                        <Box fontWeight="fontWeightBold">Nettobelopp</Box>
                      </TableCell>
                      <TableCell colSpan={5} align="right">
                        {formatMoney(netPrice)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="right">
                        <Box fontWeight="fontWeightBold">Moms</Box>
                      </TableCell>
                      <TableCell colSpan={5} align="right">
                        {formatMoney(totalVAT)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="right">
                        <Box fontWeight="fontWeightBold">Totalt</Box>
                      </TableCell>
                      <TableCell colSpan={5} align="right">
                        <Box fontWeight="fontWeightBold">
                          {formatMoney(totalPrice)}
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="right">
                        <Box fontWeight="fontWeightBold">
                          Summa återbetalning
                        </Box>
                      </TableCell>
                      <TableCell colSpan={4} align="right">
                        <Box fontWeight="fontWeightBold">
                          {formatMoney(
                            values.lineItems.reduce(
                              (sum, l) => sum + l.refund * 100,
                              0,
                            ),
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </TablePaper>{' '}
            {['notification', 'autogiro'].includes(paymentMethod) && (
              <Field
                component={TextField}
                name="receiverName"
                label="Mottagarens namn"
                variant="outlined"
              />
            )}
            {['notification', 'autogiro'].includes(paymentMethod) && (
              <>
                <Field
                  label="Betalmetod"
                  component={TextField}
                  select
                  name="receivingPaymentMethod"
                  variant="outlined"
                >
                  {toOptions(billectaPaymentMethods).map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.text}
                    </MenuItem>
                  ))}
                </Field>
                {values.receivingPaymentMethod === 'BankGiro' && (
                  <Field
                    component={TextField}
                    name="bankgiroNo"
                    label="Bankgironummer"
                    variant="outlined"
                  />
                )}
                {values.receivingPaymentMethod === 'PlusGiro' && (
                  <Field
                    component={TextField}
                    name="plusgiroNo"
                    label="PlusGironummer"
                    variant="outlined"
                  />
                )}
                {values.receivingPaymentMethod === 'BankAccount' && (
                  <>
                    <NumberField
                      component={TextField}
                      name="clearingNo"
                      label="Clearingnummer"
                      variant="outlined"
                      value={values.clearingNo}
                    />
                    <Field
                      component={TextField}
                      name="accountNo"
                      label="Kontonummer"
                      variant="outlined"
                    />
                  </>
                )}
              </>
            )}
            {isSubmitting && <LinearProgress />}
            <Box>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Återbetala
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}

OrderRefund.propTypes = {
  data: PropTypes.shape({
    getOrder: orderPropType.isRequired,
  }).isRequired,
};
