import { GetApp } from '@mui/icons-material';
import { Box, Button, MenuItem, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { dateTypes } from 'trhub-utils';

import ProductSelect from '~/components/ReportProductSelect';
import useQueryParams from '~/hooks/useQueryParams';
import shallowEqual from '~/utils/shallowEqual';
import toOptions from '~/utils/toOptions';

import SubscriptionStatsListResults from './SubscriptionStatsListResult';

const useStyles = makeStyles(theme => ({
  textField: {
    maxWidth: 170,
    minWidth: 125,
    marginTop: 10,
  },
  productTextField: {
    maxWidth: 300,
    minWidth: 260,
    marginTop: 10,
  },
  box: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const initialValues = {
  dateType: 'daily',
  from: '',
  to: '',
};

export default function SubscriptionStatsList() {
  const classes = useStyles();
  const location = useLocation();

  const dateTypeOptions = toOptions(dateTypes, false);

  const { state, params, handleChange, clear } = useQueryParams(initialValues);

  return (
    <>
      <Box className={classes.box} display="flex">
        <Box
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          columnGap="10px"
          marginTop="-10px"
        >
          <ProductSelect />
          <TextField
            className={classes.productTextField}
            label="Datumtyp"
            variant="outlined"
            select
            value={state.dateType}
            onChange={e => handleChange('dateType', e.target.value)}
          >
            {dateTypeOptions.map(dateType => (
              <MenuItem key={dateType.value} value={dateType.value}>
                {dateType.text}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className={classes.textField}
            label="Från"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            value={state.from}
            onChange={e => handleChange('from', e.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Till"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            value={state.to}
            onChange={e => handleChange('to', e.target.value)}
          />
          {!shallowEqual(initialValues, state) && (
            <Button
              color="primary"
              sx={{
                textTransform: 'none',
              }}
              onClick={clear}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>
      </Box>
      <Box>
        <SubscriptionStatsListResults params={params} />
      </Box>
      <Box display="flex" className={classes.box}>
        <Box ml="auto">
          <Button
            color="primary"
            variant="contained"
            startIcon={<GetApp />}
            component="a"
            target="_blank"
            href={`/api/export/subscriptionstats${location.search}`}
            rel="noreferrer"
          >
            Ladda ner
          </Button>
        </Box>
      </Box>
    </>
  );
}
