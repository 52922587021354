import { Switch, useRouteMatch } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';

import BettipStatsList from './BettipStatsList';

export default function BettipStats() {
  const { path } = useRouteMatch();

  return (
    <>
      <Breadcrumb name="Speltipsstatistik" />
      <Switch>
        <ErrorBoundRoute path={path} exact>
          <BettipStatsList />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
