import { Redirect, Switch, useRouteMatch } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';

import BettipStats from './BettipStats';
import SubscriptionsStats from './SubscriptionStats';

export default function Statistics() {
  const { path } = useRouteMatch();

  return (
    <>
      <Breadcrumb name="Statistik" />
      <Switch>
        <ErrorBoundRoute path={`${path}/bettipstats`}>
          <BettipStats />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/prenstats`}>
          <SubscriptionsStats />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={path} exact>
          <Redirect to={`${path}/prenstats`} />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
