import { GetApp } from '@mui/icons-material';
import { Box, Button, MenuItem, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { betTypes, sites } from 'trhub-utils';

import useQueryParams from '~/hooks/useQueryParams';
import shallowEqual from '~/utils/shallowEqual';
import toOptions from '~/utils/toOptions';

import BettipStatsListResults from './BettipStatsListResult';

const useStyles = makeStyles(theme => ({
  textField: {
    maxWidth: 170,
    minWidth: 125,
    marginTop: 10,
  },
  box: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const initialValues = {
  site: '',
  betType: '',
  sort: 'roundDate',
};

export default function BettipStatsList() {
  const classes = useStyles();
  const location = useLocation();

  const options = {
    sites: toOptions(sites),
    betTypes: toOptions(betTypes),
    sort: toOptions({
      roundDate: 'Datum',
      'sales.total': 'Totalt',
      'sales.regular': 'Ordinarie pris',
      'sales.campaign': 'Kampanj',
      'sales.ticket': 'Klippkort',
      'sales.free': 'Gratis',
      'sales.revenue': 'Nettoinkomst',
    }),
  };

  const { state, params, handleChange, clear } = useQueryParams(initialValues);

  return (
    <>
      <Box className={classes.box} display="flex">
        <Box
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          columnGap="10px"
          marginTop="-10px"
        >
          <TextField
            className={classes.textField}
            label="Sajt"
            variant="outlined"
            value={state.site}
            select
            onChange={e => handleChange('site', e.target.value)}
          >
            <MenuItem value="">
              <em>Alla</em>
            </MenuItem>
            {options.sites.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className={classes.textField}
            label="Spelform"
            variant="outlined"
            value={state.betType}
            select
            onChange={e => handleChange('betType', e.target.value)}
          >
            <MenuItem value="">
              <em>Alla</em>
            </MenuItem>
            {options.betTypes.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className={classes.textField}
            label="Sortera på"
            variant="outlined"
            value={state.sort}
            select
            onChange={e => handleChange('sort', e.target.value)}
          >
            <MenuItem value="">
              <em>Alla</em>
            </MenuItem>
            {options.sort.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>
          {!shallowEqual(initialValues, state) && (
            <Button
              color="primary"
              sx={{
                textTransform: 'none',
              }}
              onClick={clear}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>
      </Box>
      <Box>
        <BettipStatsListResults params={params} />
      </Box>
      <Box display="flex" className={classes.box}>
        <Box ml="auto">
          <Button
            color="primary"
            variant="contained"
            startIcon={<GetApp />}
            component="a"
            target="_blank"
            href={`/api/export/subscriptionstats${location.search}`}
            rel="noreferrer"
          >
            Ladda ner
          </Button>
        </Box>
      </Box>
    </>
  );
}
