import { useQuery } from '@apollo/client';
import {
  Button,
  Chip,
  CircularProgress,
  Link,
  TableCell,
  TableRow,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Link as RouterLink, NavLink, useLocation } from 'react-router-dom';

import ErrorMessage from '~/components/ErrorMessage';
import NoData from '~/components/NoData';
import TableList from '~/components/TableList';
import Time from '~/components/Time';
import usePagination from '~/hooks/usePagination';

import query from './_GetSubscriptionsByUser.gql';

/**
 * List subscriptions for a user or a payee.
 */
export default function Subscriptions({ user, payee, collectionPayee }) {
  const { pathname } = useLocation();

  const { page, pageSize, updatePage, updateRowSize } = usePagination(10);

  let title = 'Prenumerationer';
  if (payee) {
    title = collectionPayee
      ? 'Samlingsbetalda prenumerationer'
      : 'Betalda prenumerationer';
  }
  const variables = { page: page, limit: pageSize };
  if (payee) {
    variables.payee = payee;
  } else {
    variables.user = user;
  }
  const { error, loading, data } = useQuery(query, { variables });

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (loading) {
    return <CircularProgress />;
  }

  let headers = ['Startdatum', 'Produkt', 'Status'];
  if (payee) {
    headers = ['Startdatum', 'Produkt', 'Mottagare', 'Status'];
  }

  const res = data?.getSubscriptionsByUser;

  return (
    <TableList
      title={title}
      page={page}
      pageSize={pageSize}
      data={res}
      headers={headers}
      updatePage={updatePage}
      updateRowSize={updateRowSize}
      button={
        collectionPayee ? (
          <>
            <Button
              variant="contained"
              color="primary"
              component={NavLink}
              to={`${pathname}/order/collection/payee/add`}
            >
              Skapa samlingsfaktura
            </Button>
            <Button
              variant="contained"
              color="primary"
              component={NavLink}
              to={`${pathname}/subscription/add`}
            >
              Lägg till prenumeration
            </Button>
          </>
        ) : user ? (
          <Button
            variant="contained"
            color="primary"
            component={NavLink}
            to={`${pathname}/subscription`}
          >
            Ge gratisprenumeration
          </Button>
        ) : undefined
      }
    >
      {res?.subscriptions.length > 0 ? (
        res.subscriptions.map(subscription => (
          <TableRow key={subscription.id}>
            <TableCell>
              <Time date={new Date(subscription.startDate)} />
            </TableCell>
            <TableCell>
              <Link
                component={RouterLink}
                to={`/subscriptions/${subscription.id}`}
              >
                {subscription.product.name}
              </Link>
            </TableCell>
            {payee && (
              <TableCell>
                <Link
                  component={RouterLink}
                  to={`/users/${subscription.user.id}`}
                >
                  {subscription.user.label}
                </Link>
              </TableCell>
            )}
            <TableCell>
              <Chip
                color="primary"
                label={
                  subscription.isPaused
                    ? 'Pausad'
                    : subscription.isActive
                      ? 'Aktiv'
                      : 'Inaktiv'
                }
                size="small"
              />
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={headers.length}>
            <NoData
              text={
                payee
                  ? 'Den här användaren betalar inte för någon annan'
                  : 'Den här användaren har inga prenumerationer'
              }
            />
          </TableCell>
        </TableRow>
      )}
    </TableList>
  );
}

Subscriptions.propTypes = {
  user: PropTypes.string,
  payee: PropTypes.string,
  collectionPayee: PropTypes.bool,
};
