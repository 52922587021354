import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Link as MuiLink,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

const BreadcrumbsContext = createContext({});

export function BreadcrumbsProvider({ children }) {
  const [crumbs, setCrumbs] = useState([]);

  const addCrumb = useCallback(crumb => {
    setCrumbs(prev => [...prev, crumb]);
  }, []);

  const removeCrumb = useCallback(path => {
    setCrumbs(prev => prev.filter(item => item.to !== path));
  }, []);

  const context = {
    crumbs,
    addCrumb,
    removeCrumb,
  };

  return (
    <BreadcrumbsContext.Provider value={context}>
      {children}
    </BreadcrumbsContext.Provider>
  );
}

BreadcrumbsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function Breadcrumb({ name }) {
  const { addCrumb, removeCrumb } = useContext(BreadcrumbsContext);
  const { url } = useRouteMatch();

  useEffect(() => {
    addCrumb({
      text: name,
      to: url,
    });

    return () => removeCrumb(url);
  }, [name, url, addCrumb, removeCrumb]);

  return null;
}

Breadcrumb.propTypes = {
  name: PropTypes.string.isRequired,
};

export function Breadcrumbs() {
  const { crumbs } = useContext(BreadcrumbsContext);

  if (crumbs.length === 0) {
    return null;
  }

  const trail = crumbs.slice(0, -1);
  const [active] = crumbs.slice(-1);

  return (
    <Box mb={3}>
      <MuiBreadcrumbs aria-label="breadcrumb">
        {trail.map(item => (
          <MuiLink key={item.to} color="inherit" to={item.to} component={Link}>
            {item.text}
          </MuiLink>
        ))}
        <Typography color="textPrimary">{active.text}</Typography>
      </MuiBreadcrumbs>
    </Box>
  );
}
