import { Switch, useRouteMatch } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';

import CanceledSubscriptionsList from './CanceledSubscriptionsList';

export default function Complaints() {
  const { path } = useRouteMatch();

  return (
    <>
      <Breadcrumb name="Nyligen avslutade prenumerationer" />
      <Switch>
        <ErrorBoundRoute path={path} exact>
          <CanceledSubscriptionsList />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
