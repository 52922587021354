import { useQuery } from '@apollo/client';
import { GetApp } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format, startOfMonth, subMonths } from 'date-fns';
import { useState } from 'react';
import { organizations } from 'trhub-utils';

import ErrorMessage from '~/components/ErrorMessage';
import SelectField from '~/components/Select';
import useQueryParams from '~/hooks/useQueryParams';
import query from '~/utils/_GetProducts.gql';

import DebtListResults from './DebtListResults';

const useStyles = makeStyles(theme => ({
  textField: {
    maxWidth: 350,
    minWidth: 175,
    marginTop: 10,
  },
  box: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const initialValues = {
  organization: 'trmedia_ab',
  month: format(startOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd').slice(
    0,
    -3,
  ),
  product: '',
};

export default function DebtList() {
  const classes = useStyles();

  const { state, params, clear, handleChange } = useQueryParams(initialValues);

  const { data } = useQuery(query, {
    variables: {
      limit: 150,
      filter: { productType: 'subscription' },
    },
  });
  const productList = data?.list?.items ?? [];

  const [error] = useState({ message: '' });

  if (!productList) {
    return <CircularProgress />;
  }

  const urlParams = new URLSearchParams();

  if (state.organization) {
    urlParams.set('organization', state.organization);
  }
  if (state.month) {
    urlParams.set('month', state.month);
  }
  if (state.product) {
    urlParams.set('product', params.product);
  }

  if (error.message) {
    return <ErrorMessage error={error} />;
  }

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h5" component="h2">
            Prenskuld
          </Typography>
          <Typography
            variant="body2"
            component="p"
            sx={theme => ({
              marginTop: theme.spacing(2),
            })}
          >
            Välj ett månadsintervall för att sedan generera csv-filen. Observera
            att det kan ta ett par minuter för att skapa filen.
          </Typography>
        </CardContent>
      </Card>
      <Box className={classes.box} display="flex">
        <Box
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          columnGap="10px"
          marginTop="-10px"
        >
          <SelectField
            className={classes.textField}
            label="Organisation"
            value={state.organization}
            onChange={e => handleChange('organization', e.target.value)}
            options={Object.entries(organizations).map(([value, label]) => ({
              value,
              label,
            }))}
          />
          <TextField
            className={classes.textField}
            label="Månad"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="month"
            value={state.month}
            onChange={e => handleChange('month', e.target.value)}
          />
          <SelectField
            className={classes.textField}
            label="Produkt"
            value={state.product}
            onChange={e => handleChange('product', e.target.value)}
            options={[
              { value: 'all', label: 'Alla' },
              ...(productList
                ? productList.map(item => ({
                    value: item.id,
                    label: item.name,
                  }))
                : []),
            ]}
          />

          {Object.values(state).some(property => property !== '') && (
            <Button
              color="primary"
              sx={theme => ({
                margin: theme.spacing(1),
                textTransform: 'none',
              })}
              onClick={() => clear()}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>
      </Box>
      <Box display="flex" className={classes.box}>
        <Box
          ml="auto"
          css={({ theme }) => ({
            paddingRight: theme.spacing(2),
          })}
        >
          <Button
            color="primary"
            variant="contained"
            startIcon={<GetApp />}
            component="a"
            target="_blank"
            href={`/api/export/debt_orders?${urlParams.toString()}`}
            rel="noreferrer"
          >
            Hämta ordrar
          </Button>
        </Box>
        <Box>
          <Button
            color="primary"
            variant="contained"
            startIcon={<GetApp />}
            component="a"
            target="_blank"
            href={`/api/export/debt?${urlParams.toString()}`}
            rel="noreferrer"
          >
            Prenskuld
          </Button>
        </Box>
      </Box>
      <DebtListResults state={state} />
    </>
  );
}
