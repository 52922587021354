import { Switch, useRouteMatch } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';

import OrderBuilder from './OrderBuilder';
import OrderBuilderAdd from './OrderBuilderAdd';
import OrderBuilderList from './OrderBuilderList';

export default function OrderBuilders() {
  const { path } = useRouteMatch();

  return (
    <>
      <Breadcrumb name="Girokampanjer" />
      <Switch>
        <ErrorBoundRoute path={path} exact>
          <OrderBuilderList />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/add`} exact>
          <OrderBuilderAdd />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/:orderBuilder`}>
          <OrderBuilder />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
