import { useQuery } from '@apollo/client';
import { Add } from '@mui/icons-material';
import { Box, Button, CircularProgress, Link } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';

import ErrorMessage from '~/components/ErrorMessage';

import query from './_GetOauthClients.gql';

const columns = [
  {
    field: 'clientId',
    flex: 2,
    headerName: 'Client ID',
    renderCell: params => (
      <Link component={RouterLink} to={`/settings/oauth/${params.value}`}>
        {params.value}
      </Link>
    ),
  },
  {
    field: 'allowProxy',
    flex: 1,
    headerName: 'Tillåt proxy',
    renderCell: params => (params.value ? 'Ja' : 'Nej'),
  },
  {
    field: 'sessionLimit',
    flex: 1,
    headerName: 'Sessionsbegränsning',
    renderCell: params => params.value || 'Ingen',
  },
  {
    field: 'nrOfSessions',
    flex: 1,
    headerName: 'Antal aktiva sessioner',
  },
];

export default function Oauthlist() {
  const { data, error, loading } = useQuery(query);
  const { path } = useRouteMatch();
  const [pageSize, setPageSize] = useState(20);

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (loading) {
    return <CircularProgress />;
  }

  const rows = data?.items || [];

  return (
    <Box>
      <Box sx={{ background: 'white' }}>
        <DataGrid
          columns={columns}
          defaultSort="clientId"
          paginationMode="client"
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20]}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          getRowId={r => r.clientId}
          rows={rows}
          autoHeight
        />
      </Box>
      <Button
        component={RouterLink}
        to={`${path}/add`}
        color="primary"
        variant="contained"
        startIcon={<Add />}
        sx={{ marginTop: 3 }}
      >
        Lägg till
      </Button>
    </Box>
  );
}
