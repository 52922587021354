import { GetApp } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { paymentMethods, sites } from 'trhub-utils';

import SelectField from '~/components/Select';
import useQueryParams from '~/hooks/useQueryParams';

import CanceledSubscriptionsListResult from './CanceledSubscriptionsListResult';

const useStyles = makeStyles(theme => ({
  textField: {
    maxWidth: 170,
    minWidth: 125,
  },
  box: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const initialValues = {
  fromEndDate: '',
  toEndDate: '',
  paymentMethod: '',
  inactiveUsersOnly: false,
  sites: ['all'],
};

export default function CanceledSubscriptionsList() {
  const classes = useStyles();

  const { params, state, handleChange } = useQueryParams(initialValues);
  const location = useLocation();

  return (
    <Box>
      <Box
        maxWidth="100%"
        display="flex"
        flexWrap="wrap"
        gridAutoFlow="column"
        columnGap="10px"
        rowGap="10px"
        alignItems="center"
        mb={2}
        sx={{
          '.MuiFormControl-root': {
            minWidth: '120px',
          },
        }}
      >
        <TextField
          className={classes.textField}
          label="Från"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          type="date"
          value={state.fromEndDate}
          onChange={e => handleChange('fromEndDate', e.target.value)}
        />
        <TextField
          className={classes.textField}
          label="Till"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          type="date"
          value={state.toEndDate}
          onChange={e => handleChange('toEndDate', e.target.value)}
        />
        <SelectField
          label="Betalmetod"
          value={state.paymentMethod}
          onChange={e => handleChange('paymentMethod', e.target.value)}
          options={[
            { value: 'all', label: 'Alla' },
            ...Object.entries(paymentMethods).map(([value, label]) => ({
              label,
              value,
            })),
          ]}
        />
        <SelectField
          label="Sajt"
          value={Array.isArray(state.sites) ? state.sites : [state.sites]}
          onChange={e => handleChange('sites', e.target.value)}
          options={[
            { value: 'all', label: 'Alla' },
            ...Object.entries(sites).map(([value, label]) => ({
              value,
              label,
            })),
          ]}
          multiselect
        />
        <FormControlLabel
          label="Inaktiva kunder"
          control={
            <Checkbox
              checked={state.inactiveUsersOnly}
              onChange={_ =>
                handleChange('inactiveUsersOnly', !state.inactiveUsersOnly)
              }
            />
          }
        />
      </Box>
      <CanceledSubscriptionsListResult params={params} />
      <Box display="flex" className={classes.box}>
        <Box ml="auto">
          <Button
            color="primary"
            variant="contained"
            startIcon={<GetApp />}
            component="a"
            target="_blank"
            href={`/api/export/canceled-subscriptions${location.search}`}
            rel="noreferrer"
          >
            Ladda ner
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
