import { useQuery } from '@apollo/client';
import {
  Box,
  Card,
  CircularProgress,
  TableCell,
  TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { formatMoney } from '@trmediaab/zebra-utils';
import { format } from 'date-fns';
import { sv } from 'date-fns/locale';
import PropTypes from 'prop-types';

import ErrorMessage from '~/components/ErrorMessage';
import TableList from '~/components/TableList';
import usePagination from '~/hooks/usePagination';

import query from './_GetDebtReports.gql';

const useStyles = makeStyles(() => ({
  tableRowSum: {
    background: 'linear-gradient(45deg, #2196F3 5%, #21CBF3 30%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
  },
}));

export default function DebtListResults({ state }) {
  const { organization, month, product } = state;

  const classes = useStyles();

  const { page, pageSize, updatePage, updateRowSize } = usePagination(15);
  const { error, data } = useQuery(query, {
    variables: {
      page: page,
      limit: pageSize,
      filter: {
        organization,
        month,
        product,
      },
    },
  });

  const res = data?.getDebtReports;
  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!res) {
    return <CircularProgress />;
  }

  const headers = ['Period', 'Produkt', 'Nettoskuld', 'Moms', 'Totalskuld'];

  return (
    <>
      <Box mb={3}>
        <Card>
          <TableList
            page={page}
            pageSize={pageSize}
            data={res}
            headers={headers}
            updatePage={updatePage}
            updateRowSize={updateRowSize}
          >
            {res?.reports.length > 0 ? (
              res.reports.map((item, index) => {
                const month = format(new Date(item.date), 'LLLL', {
                  locale: sv,
                });
                return (
                  <TableRow hover key={index}>
                    <TableCell className={classes.tableCellDate} width="10%">
                      {month.charAt(0).toUpperCase() + month.slice(1)}
                    </TableCell>
                    <TableCell width="40%">{item.productName}</TableCell>
                    <TableCell>{formatMoney(item.netDebt)}</TableCell>
                    <TableCell>{formatMoney(item.vat)}</TableCell>
                    <TableCell>{formatMoney(item.totalDebt)}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  Inga prenskulder finns för denna filtrering. Prova att ange
                  ett annat datumintervall.
                </TableCell>
              </TableRow>
            )}
          </TableList>
        </Card>
      </Box>
    </>
  );
}

DebtListResults.propTypes = {
  state: PropTypes.shape({
    organization: PropTypes.string,
    month: PropTypes.string,
    product: PropTypes.string,
  }),
};
