import { useQuery } from '@apollo/client';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import ErrorMessage from '~/components/ErrorMessage';
import TablePaper from '~/components/TablePaper';

import query from './_GetCardPaymentSuccessReport.gql';
import CardPaymentsChart from './CardPaymentsChart';

export default function CardPaymentListResult() {
  const { error, data } = useQuery(query);

  const res = data?.list?.items;

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!res) {
    return <CircularProgress />;
  }

  const chartData = [...res].reverse().map(row => ({
    ...row,
    total: row.success + row.failed,
  }));

  return (
    <>
      <CardPaymentsChart
        label="Dragningar"
        axis={[
          {
            label: 'Totalt',
            stroke: 'blue',
            key: 'total',
          },
          {
            label: 'Misslyckade',
            stroke: 'red',
            key: 'failed',
          },
        ]}
        data={chartData}
      />

      <CardPaymentsChart
        label="Förnyelser"
        axis={[
          {
            label: 'Totalt',
            stroke: 'blue',
            key: 'renewals',
          },
          {
            label: 'Misslyckade',
            stroke: 'red',
            key: 'failedFirstRenewals',
          },
          {
            label: 'Avslutade',
            stroke: 'orange',
            key: 'endedSubscriptions',
            yAxisId: 'right',
          },
        ]}
        data={chartData}
      />

      <TablePaper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left" width="120">
                  Datum
                </TableCell>
                <TableCell align="right" width="150">
                  Förnyelser (fel)
                </TableCell>
                <TableCell align="right" width="150">
                  Dragningar (fel)
                </TableCell>
                <TableCell align="left">Avslutade</TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {res?.length > 0 &&
                res.map((row, index) => (
                  <TableRow hover key={index}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell align="right">
                      {row.renewals} ({row.failedFirstRenewals})
                    </TableCell>
                    <TableCell align="right">
                      {row.success + row.failed} ({row.failed})
                    </TableCell>
                    <TableCell align="right">
                      {row.endedSubscriptions}
                    </TableCell>

                    <TableCell>
                      <ul>
                        {row.reasons.map((item, i) => (
                          <li key={i}>
                            {item.description}: <b>{item.count}</b>
                          </li>
                        ))}
                      </ul>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TablePaper>
    </>
  );
}
