import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { PictureAsPdf } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  LinearProgress,
  MenuItem,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorMessage from '~/components/ErrorMessage';
import { userPropType } from '~/propTypes';

import OrderDetail from '../Order/OrderDetail';
import * as orderQuery from './_BuildCollectionPayeeOrder.gql';
import mutation from './_CreateCollectionPayeeOrder.gql';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    marginBottom: theme.spacing(3),
  },
  field: {
    marginTop: theme.spacing(3),
    maxWidth: 275 * 2,
  },
}));

export default function UserAddCollectionOrder({ userData }) {
  const classes = useStyles();

  const payee = userData?.getUser;

  const [actionError, setActionError] = useState('');

  const apollo = useApolloClient();

  const [action] = useMutation(mutation, {
    onCompleted: async () => {
      // Ensure all lists are refreshed
      await apollo.resetStore();
    },
  });

  const history = useHistory();
  const { loading, error, data } = useQuery(orderQuery, {
    variables: { user: userData.getUser.id },
  });

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  if (loading) {
    return <CircularProgress />;
  }
  if (actionError) {
    return <ErrorMessage error={actionError} />;
  }
  const orderData = { getOrder: data.buildCollectionPayeeOrder };

  return (
    <>
      <Breadcrumb name="Skapa samlingsfaktura" />
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Skicka samlingsfaktura
          </Typography>
          <Typography
            variant="body2"
            component="p"
            sx={theme => ({
              marginTop: theme.spacing(2),
            })}
          >
            Denna order är endast ett utkast. Kontrollera prenumeranterna så att
            de överensstämmer med kundens önskemål. Backa ett steg och välj{' '}
            <strong>Lägg till prenumeration</strong> om en prenumerant. saknas.
            Om kunden inte vill fortsätta ge prenumeration till en person, välj{' '}
            <strong>Avsluta prenumeration</strong> och sätt slutdatum till 31
            dec {new Date().getFullYear()}. När du är nöjd med samlingsfakturan
            välj <strong>Skicka fakturan</strong> så kommer den att skickas till
            kunden via Billecta.
          </Typography>
        </CardContent>
      </Card>
      <OrderDetail data={orderData} />
      <Formik
        initialValues={{
          user: '',
          deliveryMethod: 'mail',
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const response = await action({
            variables: {
              user: payee.id,
              deliveryMethod: values.deliveryMethod,
            },
          }).catch(responseError => setActionError(responseError));
          setSubmitting(false);

          if (response) {
            history.push(`/users/${payee.id}`);
          }
        }}
        validateOnBlur={false}
      >
        {({ submitForm, isSubmitting }) => (
          <Form
            css={({ theme }) => ({
              '> * + *': {
                marginTop: theme.spacing(2),
              },
              '> *': {
                width: '100%',
              },
              '> .MuiFormControl-root': {
                backgroundColor: theme.palette.background.default,
              },
            })}
          >
            <Field
              label="Leveransmetod"
              component={TextField}
              select
              name="deliveryMethod"
              variant="outlined"
              className={classes.field}
            >
              <MenuItem key="brev" value="mail">
                Brev
              </MenuItem>
              <MenuItem key="e-post" value="email">
                E-post
              </MenuItem>
            </Field>
            {isSubmitting && <LinearProgress />}
            <Box mt={2} display="flex">
              <Box>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<PictureAsPdf />}
                  component="a"
                  target="_blank"
                  href={`/api/preview_invoice?payee=${payee.id}`}
                  rel="noreferrer"
                >
                  Förhandsgranska
                </Button>
              </Box>
              <Box ml="auto">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  Skicka fakturan
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}

UserAddCollectionOrder.propTypes = {
  userData: PropTypes.shape({
    getUser: userPropType,
  }).isRequired,
};
