import {
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { BreadcrumbsProvider } from '~/components/Breadcrumbs';
import Layout from '~/components/Layout';

import Apollo from './apollo';
import Auth from './auth';
import ErrorBoundary from './components/ErrorBoundary';
import GlobalStyles from './GlobalStyles';
import Routes from './routes';
import theme from './theme';

const root = createRoot(document.querySelector('#root'));

root.render(
  <ErrorBoundary>
    <StylesProvider injectFirst>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <BreadcrumbsProvider>
              <GlobalStyles />
              <Router>
                <Apollo>
                  <Auth>
                    <Layout>
                      <Routes />
                    </Layout>
                  </Auth>
                </Apollo>
              </Router>
            </BreadcrumbsProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </StylesProvider>
  </ErrorBoundary>,
);
