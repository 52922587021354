import { useQuery } from '@apollo/client';
import { GetApp } from '@mui/icons-material';
import { Box, Button, MenuItem, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { organizations, paymentProviders, sites } from 'trhub-utils';

import SelectField from '~/components/Select';
import usePagination from '~/hooks/usePagination';
import useQueryParams from '~/hooks/useQueryParams';
import shallowEqual from '~/utils/shallowEqual';
import toOptions from '~/utils/toOptions';

import GetEconomySalesReport from './_GetEconomySalesReport.gql';
import EconomySalesListResults from './EconomySalesListResult';

const useStyles = makeStyles(theme => ({
  textField: {
    maxWidth: 170,
    minWidth: 125,
    marginTop: 10,
  },
  autoComplete: {
    maxWidth: 300,
    minWidth: 260,
    marginTop: 10,
  },
  productTextField: {
    maxWidth: 300,
    minWidth: 260,
    marginTop: 10,
  },
  box: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const initialValues = {
  organization: 'trmedia_ab',
  month: format(new Date(), 'yyyy-MM'),
  site: 'all',
  paymentProvider: 'all',
};

export default function SalesList() {
  const classes = useStyles();

  const { state, params, handleChange, clear } = useQueryParams(initialValues);

  useEffect(() => {
    // Clear productId filter when filtering by site
    // to ensure valid filters
    if (state.type === 'site' && state.productId !== '') {
      handleChange('productId', '');
    }
  }, [handleChange, state.productId, state.type]);

  const urlParams = new URLSearchParams();

  if (state.organization) {
    urlParams.set('organization', state.organization);
  }
  if (state.month) {
    urlParams.set('month', state.month);
  }
  if (state.site && state.site !== 'all') {
    urlParams.set('site', state.site);
  }
  if (state.paymentProvider && state.paymentProvider !== 'all') {
    urlParams.set('paymentProvider', state.paymentProvider);
  }

  const siteOptions = toOptions(sites);
  const paymentProviderOptions = toOptions(paymentProviders);

  const { updatePage, updateRowSize } = usePagination(15);

  const {
    error: resultError,
    loading: resultLoading,
    data: resultData,
  } = useQuery(GetEconomySalesReport, {
    variables: {
      organization: params.organization,
      month: params.month,
      paymentProvider: params.paymentProvider,
      site: params.site,
    },
  });

  return (
    <>
      <Box className={classes.box} display="flex">
        <Box
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          columnGap="10px"
          marginTop="-10px"
        >
          <SelectField
            className={classes.textField}
            label="Organisation"
            value={state.organization}
            onChange={e => handleChange('organization', e.target.value)}
            options={Object.entries(organizations).map(([value, label]) => ({
              value,
              label,
            }))}
          />
          <TextField
            className={classes.textField}
            label="Månad"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="month"
            value={state.month}
            onChange={e => handleChange('month', e.target.value)}
          />
          <TextField
            className={classes.productTextField}
            label="Varumärke"
            variant="outlined"
            select
            value={state.site}
            onChange={e => handleChange('site', e.target.value)}
          >
            <MenuItem value={'all'}>Alla</MenuItem>
            {siteOptions.map(site => (
              <MenuItem key={site.value} value={site.value}>
                {site.text}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className={classes.productTextField}
            label="Betalningsleverantör"
            variant="outlined"
            select
            value={state.paymentProvider}
            onChange={e => handleChange('paymentProvider', e.target.value)}
          >
            <MenuItem value={'all'}>Alla</MenuItem>
            {paymentProviderOptions.map(paymentProvider => (
              <MenuItem
                key={paymentProvider.value}
                value={paymentProvider.value}
              >
                {paymentProvider.text}
              </MenuItem>
            ))}
          </TextField>
          {!shallowEqual(initialValues, state) && (
            <Button
              color="primary"
              sx={{
                textTransform: 'none',
              }}
              onClick={clear}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>
      </Box>
      <Box display="flex" className={classes.box}>
        <Box
          ml="auto"
          css={({ theme }) => ({
            paddingRight: theme.spacing(2),
          })}
        >
          <Button
            color="primary"
            variant="contained"
            startIcon={<GetApp />}
            component="a"
            target="_blank"
            href={`/api/export/economy_sales_orders?${urlParams.toString()}`}
            rel="noreferrer"
          >
            Hämta ordrar
          </Button>
        </Box>
        <Box>
          <Button
            color="primary"
            variant="contained"
            startIcon={<GetApp />}
            component="a"
            target="_blank"
            href={`/api/export/economy_sales?${urlParams.toString()}`}
            rel="noreferrer"
          >
            Försäljningsrapport
          </Button>
        </Box>
      </Box>
      <EconomySalesListResults
        params={params}
        loading={resultLoading}
        error={resultError}
        updatePage={updatePage}
        updateRowSize={updateRowSize}
        data={resultData}
      />
    </>
  );
}
