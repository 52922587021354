import { useQuery } from '@apollo/client';
import { GetApp } from '@mui/icons-material';
import { Autocomplete, Box, Button, MenuItem, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format, startOfMonth } from 'date-fns';
import { useEffect, useState } from 'react';
import { betTypes, dateTypes, sites } from 'trhub-utils';

import ErrorMessage from '~/components/ErrorMessage';
import usePagination from '~/hooks/usePagination';
import useQueryParams from '~/hooks/useQueryParams';
import query from '~/utils/_GetProducts.gql';
import shallowEqual from '~/utils/shallowEqual';
import toOptions from '~/utils/toOptions';

import GetAllSalesReports from './_GetAllSalesReports.gql';
import SalesListResults from './SalesListResult';

const useStyles = makeStyles(theme => ({
  textField: {
    maxWidth: 170,
    minWidth: 125,
    marginTop: 10,
  },
  autoComplete: {
    maxWidth: 300,
    minWidth: 260,
    marginTop: 10,
  },
  productTextField: {
    maxWidth: 300,
    minWidth: 260,
    marginTop: 10,
  },
  box: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const initialValues = {
  type: 'site',
  dateType: 'daily',
  productId: '',
  site: 'all',
  betType: 'all',
  from: format(startOfMonth(new Date(), 1), 'yyyy-MM-dd'),
  to: '',
};

export default function SalesList() {
  const classes = useStyles();

  const { state, params, handleChange, clear } = useQueryParams(initialValues);

  useEffect(() => {
    // Clear productId filter when filtering by site
    // to ensure valid filters
    if (state.type === 'site' && state.productId !== '') {
      handleChange('productId', '');
    }
  }, [handleChange, state.productId, state.type]);

  const urlParams = new URLSearchParams();

  if (state.from) {
    urlParams.set('from', state.from);
  }
  if (state.to) {
    urlParams.set('to', state.to);
  }

  const siteOptions = toOptions(sites);
  const betTypeOptions = toOptions(betTypes);
  const dateTypeOptions = toOptions(dateTypes, false);

  const [productSearch, setProductSearch] = useState('');

  const { loading, error, data } = useQuery(query, {
    variables: { filter: { search: productSearch } },
  });

  const { page, pageSize, updatePage, updateRowSize } = usePagination(15);

  const {
    error: resultError,
    data: resultData,
    loading: resultLoading,
  } = useQuery(GetAllSalesReports, {
    variables: {
      type: params.type,
      dateType: params.dateType,
      productId: params.productId,
      site: params.site,
      betType: params.betType,
      from: params.from || undefined,
      to: params.to || undefined,
      page: page,
      limit: pageSize,
      fetchProduct: Boolean(params.productId),
    },
  });

  const product = resultData?.getProduct;

  if (error) {
    return <ErrorMessage error={error} />;
  }

  const productList = data?.list?.items ?? [];

  return (
    <>
      <Box className={classes.box} display="flex">
        <Box
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          columnGap="10px"
          marginTop="-10px"
        >
          <TextField
            className={classes.productTextField}
            label="Typ"
            variant="outlined"
            select
            value={state.type}
            onChange={e => handleChange('type', e.target.value)}
          >
            <MenuItem value="site">
              <em>Sajter</em>
            </MenuItem>
            <MenuItem value="product">
              <em>Produkter</em>
            </MenuItem>
          </TextField>
          <TextField
            className={classes.productTextField}
            label="Varumärke"
            variant="outlined"
            select
            value={state.site}
            onChange={e => handleChange('site', e.target.value)}
          >
            <MenuItem value={'all'}>
              <em>Alla</em>
            </MenuItem>
            {siteOptions.map(site => (
              <MenuItem key={site.value} value={site.value}>
                {site.text}
              </MenuItem>
            ))}
          </TextField>
          {state.type === 'product' && (
            <>
              <TextField
                className={classes.productTextField}
                label="Spelform"
                variant="outlined"
                select
                value={state.betType}
                onChange={e => handleChange('betType', e.target.value)}
              >
                <MenuItem value={'all'}>
                  <em>Alla</em>
                </MenuItem>
                {betTypeOptions.map(betType => (
                  <MenuItem key={betType.value} value={betType.value}>
                    {betType.text}
                  </MenuItem>
                ))}
              </TextField>
              <Autocomplete
                className={classes.autoComplete}
                noOptionsText={
                  loading ? 'Laddar in produkter...' : 'Inga träffar.'
                }
                name="products"
                options={productList}
                getOptionLabel={option => option?.name || ''}
                value={
                  state.productId
                    ? {
                        name: product?.name || '...',
                        id: state.productId,
                      }
                    : ''
                }
                isOptionEqualToValue={option => option?.id || ''}
                inputValue={productSearch}
                onChange={(_, value) =>
                  handleChange('productId', value ? value.id : '')
                }
                onInputChange={async (_, value) => {
                  setProductSearch(value);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Sök produkt"
                    variant="outlined"
                  />
                )}
              />
            </>
          )}
          <TextField
            className={classes.productTextField}
            label="Datumtyp"
            variant="outlined"
            select
            value={state.dateType}
            onChange={e => handleChange('dateType', e.target.value)}
          >
            {dateTypeOptions.map(dateType => (
              <MenuItem key={dateType.value} value={dateType.value}>
                {dateType.text}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className={classes.textField}
            label="Från"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            value={state.from}
            onChange={e => handleChange('from', e.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Till"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            value={state.to}
            onChange={e => handleChange('to', e.target.value)}
          />
          {!shallowEqual(initialValues, state) && (
            <Button
              color="primary"
              sx={{
                textTransform: 'none',
              }}
              onClick={clear}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>
      </Box>
      <Box display="flex" className={classes.box}>
        <Box ml="auto">
          <Button
            color="primary"
            variant="contained"
            startIcon={<GetApp />}
            component="a"
            target="_blank"
            href={`/api/export/sales?${urlParams.toString()}`}
            rel="noreferrer"
          >
            Försäljningsrapport
          </Button>
        </Box>
      </Box>
      <SalesListResults
        params={params}
        loading={resultLoading}
        error={resultError}
        page={page}
        pageSize={pageSize}
        updatePage={updatePage}
        updateRowSize={updateRowSize}
        data={resultData}
      />
    </>
  );
}
