import { Switch, useRouteMatch } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';

import OauthForm from './OauthForm';
import OauthList from './OauthList';

export default function Oauth() {
  const { path } = useRouteMatch();

  return (
    <>
      <Breadcrumb name="Oauth clients" />
      <Switch>
        <ErrorBoundRoute path={path} exact>
          <OauthList />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/add`} exact>
          <OauthForm />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/:clientId`}>
          <OauthForm />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
