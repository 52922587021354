import {
  Box,
  Button,
  Card,
  CardContent,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import useQueryParams from '~/hooks/useQueryParams';
import shallowEqual from '~/utils/shallowEqual';

import ComplaintListResult from './ComplaintListResult';

const useStyles = makeStyles(theme => ({
  textField: {
    maxWidth: 170,
    minWidth: 125,
    marginTop: 10,
  },
  productTextField: {
    maxWidth: 300,
    minWidth: 260,
    marginTop: 10,
  },
  box: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const initialValues = {
  distributor: '',
  from: '',
  to: '',
};

export default function ComplaintList() {
  const classes = useStyles();

  const { state, params, handleChange, clear } = useQueryParams(initialValues);

  const urlParams = new URLSearchParams();

  if (state.from) {
    urlParams.set('from', state.from);
  }
  if (state.to) {
    urlParams.set('to', state.to);
  }
  if (state.distributor) {
    urlParams.set('distributor', state.distributor);
  }

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h5" component="h2">
            Reklamationer av Travrondens utskick
          </Typography>
          <Typography
            variant="body2"
            component="p"
            sx={theme => ({
              marginTop: theme.spacing(2),
            })}
          >
            Filtrera i tabellen genom att välja datum och/eller distributör.
          </Typography>
        </CardContent>
      </Card>
      <Box className={classes.box} display="flex">
        <Box
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          columnGap="10px"
          marginTop="-10px"
        >
          <TextField
            className={classes.productTextField}
            label="Distributör"
            variant="outlined"
            select
            value={state.distributor}
            onChange={e => handleChange('distributor', e.target.value)}
          >
            <MenuItem value={''}>
              <em>Alla</em>
            </MenuItem>
            {['MTD', 'PostNord'].map(distributor => (
              <MenuItem key={distributor} value={distributor}>
                {distributor}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className={classes.textField}
            label="Från"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            value={state.from}
            onChange={e => handleChange('from', e.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Till"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            value={state.to}
            onChange={e => handleChange('to', e.target.value)}
          />
          {!shallowEqual(initialValues, state) && (
            <Button
              color="primary"
              sx={{
                textTransform: 'none',
              }}
              onClick={clear}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>
      </Box>
      <ComplaintListResult params={params} />
    </>
  );
}
