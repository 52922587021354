import { useQuery } from '@apollo/client';
import { Add, GetApp } from '@mui/icons-material';
import { Box, Button, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link, useLocation } from 'react-router-dom';
import { paymentMethods, sites } from 'trhub-utils';

import SelectField from '~/components/Select';
import useAuthUser from '~/hooks/useAuthUser';
import useQueryParams from '~/hooks/useQueryParams';
import shallowEqual from '~/utils/shallowEqual';

import GetSubscriptionsProducts from './_GetSubscriptionProducts.gql';
import SubscriptionListResult from './SubscriptionListResult';

const useStyles = makeStyles(theme => ({
  box: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const initialValues = {
  active: '',
  paymentMethod: '',
  sites: '',
  user: '',
  dueDate: '',
  notEnded: '',
  product: '',
};

export default function SubscriptionList() {
  const classes = useStyles();
  const location = useLocation();

  const { isAdmin } = useAuthUser();

  const { params, state, handleChange, clear } = useQueryParams(initialValues);
  const { data } = useQuery(GetSubscriptionsProducts);

  return (
    <>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Box
          maxWidth="100%"
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          columnGap="10px"
          rowGap="10px"
          alignItems="center"
          sx={{
            '.MuiFormControl-root': {
              minWidth: '120px',
            },
          }}
        >
          <SelectField
            label="Aktiva"
            value={
              typeof state.active === 'boolean'
                ? state.active.toString()
                : state.active
            }
            onChange={e => handleChange('active', e.target.value)}
            options={[
              { value: 'all', label: 'Alla' },
              { value: 'true', label: 'Ja' },
              { value: 'false', label: 'Nej' },
            ]}
          />

          <SelectField
            label="Tillsvidare"
            value={
              typeof state.notEnded === 'boolean'
                ? state.notEnded.toString()
                : state.notEnded
            }
            onChange={e => handleChange('notEnded', e.target.value)}
            options={[
              { value: 'all', label: 'Alla' },
              { value: 'true', label: 'Ja' },
              { value: 'false', label: 'Nej' },
            ]}
          />

          <SelectField
            label="Betalmetod"
            value={state.paymentMethod}
            onChange={e => handleChange('paymentMethod', e.target.value)}
            options={[
              { value: 'all', label: 'Alla' },
              ...Object.entries(paymentMethods).map(([value, label]) => ({
                label,
                value,
              })),
            ]}
          />

          <TextField
            label="Användare"
            variant="outlined"
            value={state.user}
            onChange={e => handleChange('user', e.target.value)}
          />

          <TextField
            label="Förfallodatum"
            variant="outlined"
            value={state.dueDate}
            onChange={e => handleChange('dueDate', e.target.value)}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />

          <SelectField
            label="Sajt"
            value={state.sites}
            onChange={e => handleChange('sites', e.target.value)}
            options={[
              { value: 'all', label: 'Alla' },
              ...Object.entries(sites).map(([value, label]) => ({
                value,
                label,
              })),
            ]}
          />

          <SelectField
            label="Produkt"
            value={state.product}
            onChange={e => handleChange('product', e.target.value)}
            options={[
              { value: 'all', label: 'Alla' },
              ...(data
                ? data.items.map(item => ({ value: item.id, label: item.name }))
                : []),
            ]}
          />

          {!shallowEqual(initialValues, state) && (
            <Button
              color="primary"
              sx={theme => ({
                margin: theme.spacing(1),
                textTransform: 'none',
              })}
              onClick={clear}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>

        {isAdmin && (
          <Box display="flex">
            <Button
              component={Link}
              to={`${location.pathname}/add`}
              color="primary"
              variant="contained"
              startIcon={<Add />}
              size="medium"
            >
              Lägg till
            </Button>
          </Box>
        )}
      </Box>

      <Box>
        <SubscriptionListResult params={params} />
      </Box>

      <Box display="flex" className={classes.box}>
        <Box ml="auto">
          <Button
            color="primary"
            variant="contained"
            startIcon={<GetApp />}
            component="a"
            target="_blank"
            href={`/api/export/subscriptions${location.search}`}
            rel="noreferrer"
          >
            Ladda ner
          </Button>
        </Box>
      </Box>
    </>
  );
}
