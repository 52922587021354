import { useApolloClient, useMutation } from '@apollo/client';
import { Box, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ErrorMessage from '~/components/ErrorMessage';

import mutation from './_ResetTwoFactor.gql';

export default function ResetTwoFactor({ user }) {
  const apollo = useApolloClient();
  const history = useHistory();

  const [error, setError] = useState('');

  const [action] = useMutation(mutation, {
    onCompleted: async () => {
      // Ensure all lists are refreshed
      await apollo.resetStore();
    },
  });

  const handleResetTwoFactor = useCallback(async () => {
    const response = await action({
      variables: {
        id: user,
      },
    }).catch(responseError => setError(responseError));

    if (response) {
      history.push(`/users/${user}`);
    }

    setOpen(false);
  }, [action, history, user]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        sx={theme => ({
          backgroundColor: theme.palette.info.main,
          color: theme.palette.text.main,
          '&:hover': {
            background: theme.palette.info.dark,
          },
        })}
        onClick={handleClickOpen}
        variant="contained"
      >
        Återställ 2FA
      </Button>
      {error ? (
        <Box
          sx={theme => ({
            marginTop: theme.spacing(2),
          })}
        >
          <ErrorMessage error={error} />
        </Box>
      ) : (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Återställ tvåfaktorsautentisering</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Användaren kommer att behöva aktivera tvåfaktorsautentisering på
              minasidor inom 7 dagar.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Avbryt
            </Button>
            <Button
              onClick={handleResetTwoFactor}
              sx={theme => ({
                color: theme.palette.error.main,
              })}
            >
              Återställ
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

ResetTwoFactor.propTypes = {
  user: PropTypes.string.isRequired,
};
