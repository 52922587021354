import { Box, Button, LinearProgress, MenuItem } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import PropTypes from 'prop-types';
import { termsStatus } from 'trhub-utils';
import * as Yup from 'yup';

import TextEditor from '~/components/TextEditor';
import { termsType } from '~/propTypes';

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  version: Yup.string().required(),
  text: Yup.string().required(),
  status: Yup.string().required(),
});

export const defaultValues = {
  name: '',
  version: '',
  text: '',
  status: 'draft',
};

export default function TermsForm({ initialValues = {}, onSubmit }) {
  return (
    <Formik
      initialValues={{
        ...defaultValues,
        ...initialValues,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ submitForm, isSubmitting, setFieldValue, values }) => (
        <Form
          css={({ theme }) => ({
            '> * + *': {
              marginTop: theme.spacing(2),
            },
            '> *': {
              width: '100%',
            },
            '> .MuiFormControl-root': {
              backgroundColor: theme.palette.background.default,
            },
          })}
        >
          <Field
            component={TextField}
            name="name"
            label="Name"
            variant="outlined"
          />
          <Field
            component={TextField}
            name="version"
            label="Version"
            variant="outlined"
          />
          <Field
            component={TextField}
            name="status"
            label="Status"
            select
            variant="outlined"
          >
            {Object.entries(termsStatus).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </Field>
          <TextEditor
            height={800}
            value={values.text}
            onChange={({ text }) => setFieldValue('text', text)}
          />

          {isSubmitting && <LinearProgress />}
          <Box>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              Spara
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

TermsForm.propTypes = {
  initialValues: termsType,
  onSubmit: PropTypes.func.isRequired,
};
