import { useQuery } from '@apollo/client';
import { CircularProgress, Link, TableCell, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { formatMoney } from '@trmediaab/zebra-utils';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import ErrorMessage from '~/components/ErrorMessage';
import TableList from '~/components/TableList';
import Time from '~/components/Time';

import query from './_GetGiftCodeDebt.gql';

const useStyles = makeStyles(() => ({
  tableRowSum: {
    background: 'linear-gradient(45deg, #2196F3 5%, #21CBF3 30%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
  },
}));

export default function GiftCodeDebtListResult({ params }) {
  const classes = useStyles();
  const { month } = params;

  const { error, loading, data } = useQuery(query, {
    variables: {
      filter: {
        month,
      },
    },
  });
  const res = data?.list;

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (loading) {
    return <CircularProgress />;
  }

  const headers = ['Period', 'Produkt', 'Total skuld'];

  return (
    <TableList data={res} headers={headers}>
      {res?.items.length > 0 ? (
        res.items.map((item, index) => (
          <Fragment key={index}>
            <TableRow>
              <TableCell className={classes.siteRowCell}>
                <Time
                  key={`${item.date}_created`}
                  date={new Date(month)}
                  format="yyyy-MM"
                />
              </TableCell>
              <TableCell className={classes.siteRowCell}>
                <Link
                  component={RouterLink}
                  to={`/products/${item.product.id}`}
                >
                  {item.product.name}
                </Link>
              </TableCell>
              <TableCell className={classes.siteRowCell}>
                {formatMoney(item.debt)}
              </TableCell>
            </TableRow>
          </Fragment>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={10} align="center">
            Inget rapportdata finns för denna filtrering.
          </TableCell>
        </TableRow>
      )}
    </TableList>
  );
}

GiftCodeDebtListResult.propTypes = {
  params: PropTypes.shape({
    month: PropTypes.string.isRequired,
  }).isRequired,
};
