import { BorderColorSharp as Icon } from '@mui/icons-material';
import { Button } from '@mui/material';
import { PluginComponent } from 'react-markdown-editor-lite';

export default class MarkPlugin extends PluginComponent {
  static pluginName = 'font-mark';
  static align = 'left';

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const selectedText = this.editor.getSelection().text;
    const decorator = '==';
    this.editor.insertText(decorator + selectedText + decorator, true, {
      start: decorator.length,
      end: decorator.length + selectedText.length,
    });
  }

  render() {
    return (
      <Button
        className="button button-type-mark"
        size="small"
        title="Mark"
        disableRipple
        onClick={this.handleClick}
      >
        <Icon fontSize="small" />
      </Button>
    );
  }
}
