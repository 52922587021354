import { useApolloClient, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';

import addOrderBuilderMutation from './_AddOrderBuilder.gql';
import OrderBuilderForm from './OrderBuilderForm';

export default function OrderBuilderAdd() {
  const apollo = useApolloClient();

  const [action] = useMutation(addOrderBuilderMutation, {
    onCompleted: async () => {
      // Ensure all lists are refreshed
      await apollo.resetStore();
    },
  });

  const history = useHistory();

  return (
    <>
      <Breadcrumb name="Lägg till ny" />

      <OrderBuilderForm
        initialValues={{
          name: '',
          product: {},
          price: 0,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const response = await action({
            variables: {
              input: {
                ...values,
                product: values.product.id,
                price: Number(values.price) * 100,
              },
            },
          });
          setSubmitting(false);
          history.push(`/order-builders/${response.data.addOrderBuilder.id}`);
        }}
      />
    </>
  );
}
