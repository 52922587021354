import { useQuery } from '@apollo/client';
import { Alert, TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Field, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import query from '~/utils/_GetCampaigns.gql';

export default function CampaignAutocomplete({ filter }) {
  const { errors, setFieldValue } = useFormikContext();
  const [campaignSearch, setCampaignSearch] = useState('');

  const refetchCampaign = useCallback(search => {
    setCampaignSearch(search);
  }, []);

  const { loading, error, data } = useQuery(query, {
    variables: {
      filter: {
        products: filter?.products,
        type: filter?.type,
        site: filter?.site,
        search: campaignSearch,
      },
    },
  });

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  const campaignList = data?.list?.items ?? [];

  return (
    <>
      <Field
        noOptionsText={loading ? 'Laddar in kampanjer...' : 'Inga träffar.'}
        name="campaign"
        component={Autocomplete}
        filterSelectedOptions
        options={campaignList}
        getOptionKey={option => option.id}
        getOptionLabel={option => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        filterOptions={createFilterOptions({
          limit: 150,
        })}
        onInputChange={async (_, value) => {
          refetchCampaign(value);
        }}
        onChange={(_, value) => setFieldValue('campaign', value)}
        renderInput={params => (
          <TextField
            {...params}
            label="Sök kampanj"
            variant="outlined"
            error={errors.campaign != null}
            helperText={errors.campaign}
          />
        )}
      />
    </>
  );
}

CampaignAutocomplete.propTypes = {
  filter: PropTypes.shape({
    products: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string,
    site: PropTypes.string,
  }),
};
