import qs from 'qs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export default function useQueryParams(initialValues = {}) {
  const { search } = useLocation();
  const history = useHistory();

  // Parse and decode query parameters
  // qs only parse to strings by default so we use a custom decoders
  const params = useMemo(
    () =>
      qs.parse(decodeURI(search).slice(1), {
        decoder: value => {
          // When searching for numbers starting with 0, e.g. the four last digits
          // of a payment card. ParseInt would remove the leading zero, so keep it as a string.
          if (/^(-?\d+)$/.test(value) && !value.startsWith('0')) {
            return Number.parseInt(value);
          }
          const keywords = {
            true: true,
            false: false,
            null: null,
            undefined: undefined,
          };

          if (Object.hasOwnProperty.call(keywords, value)) {
            return keywords[value];
          }

          if (value.includes(',')) {
            return value.split(',').map(item => item.trim());
          }

          if (value.includes('%')) {
            return decodeURIComponent(value);
          }

          return value;
        },
      }),
    [search],
  );

  // The state is used for form inputs to avoid the debounce latency when typing
  const initState = { ...initialValues };
  Object.keys(initState).forEach(key => {
    if (Object.hasOwnProperty.call(params, key)) {
      initState[key] = params[key];
    }
  });

  const [state, setState] = useState(initState);

  // Debounce timer
  const timeout = useRef(null);
  // Update the path with some debounce
  const updateHistory = useCallback(
    search => {
      const func = search => {
        const method = history.location.search === '' ? 'push' : 'replace';
        history[method]({
          search,
        });
      };

      const later = () => {
        timeout.current = null;
        Reflect.apply(func, this, [search]);
      };

      clearTimeout(timeout.current);
      timeout.current = setTimeout(later, 300);
    },
    [history],
  );

  const handleChanges = useCallback(
    values => {
      const newState = {
        ...state,
        ...values,
      };

      const encodedUri = Object.keys({ ...newState, ...params }).reduce(
        (acc, key) => {
          let encodedValue = newState[key] || params[key];

          if (Array.isArray(encodedValue)) {
            encodedValue = encodedValue
              .map(value => encodeURIComponent(value))
              .join(',');
          }

          return { ...acc, [key]: encodedValue };
        },
        {},
      );

      updateHistory(qs.stringify(encodedUri, { encode: false }));
      setState(newState);
    },
    [updateHistory, state, params],
  );

  // onchange handler updating the path and the state
  const handleChange = useCallback(
    (type, value) => {
      let updatedValue;

      if (Array.isArray(value)) {
        if (value[0] === 'all') {
          // other value is selected, remove 'all'
          updatedValue = value.filter(item => item !== 'all');
        } else if (value[value.length - 1] === 'all') {
          // 'all' is selected, remove all other selected
          updatedValue = ['all'];
        } else {
          updatedValue = value;
        }
      } else {
        updatedValue = value === undefined ? '' : value;
      }

      handleChanges({ [type]: updatedValue });
    },
    [handleChanges],
  );

  // Callback to reset the state
  const clear = useCallback(() => {
    history.push({
      search: '',
    });
    setState(initialValues);
  }, [history, initialValues]);

  // Ensure timeout is not running after unmount
  useEffect(() => () => clearTimeout(timeout.current), []);

  // clear state when navigate in menu
  useEffect(() => {
    if (search === '') {
      setState(initialValues);
    }
  }, [search, initialValues]);

  return {
    state,
    params: { ...initialValues, ...params },
    clear,
    handleChange,
    handleChanges,
  };
}
