import { CircularProgress, TableCell, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { formatMoney } from '@trmediaab/zebra-utils';
import { format } from 'date-fns';
import { sv } from 'date-fns/locale';
import PropTypes from 'prop-types';
import { Fragment } from 'react';

import ErrorMessage from '~/components/ErrorMessage';
import TableList from '~/components/TableList';

const useStyles = makeStyles(() => ({
  tableRowSum: {
    background: 'linear-gradient(45deg, #2196F3 5%, #21CBF3 30%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
  },
  siteRow: {
    backgroundColor: 'lightgray',
  },
  siteRowCell: {
    fontWeight: 'bold',
  },
  paymentProviderRow: {
    fontStyle: 'italic',
  },
}));

const TransformDate = ({ date }) => {
  const month = format(new Date(date), 'LLLL', { locale: sv });
  return <>{month.charAt(0).toUpperCase() + month.slice(1)}</>;
};

export default function EconomySalesListResults({
  data,
  error,
  loading,
  updatePage,
  updateRowSize,
}) {
  const classes = useStyles();

  const res = data?.getEconomySalesReport;

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!res || loading) {
    return <CircularProgress />;
  }

  const headers = [
    'Varumärke',
    '',
    '',
    '',
    'Antal',
    'Förs. ex moms',
    'Moms 6%',
    'Moms 25%',
    'Moms total',
    'Förs. ink moms',
  ];

  return (
    <TableList
      data={res}
      headers={headers}
      updatePage={updatePage}
      updateRowSize={updateRowSize}
    >
      {res?.sites.length > 0 ? (
        res.sites.map((site, index) => (
          <Fragment key={index}>
            <TableRow>
              <TableCell colSpan={4} className={classes.siteRowCell}>
                {site.name}
              </TableCell>
              <TableCell className={classes.siteRowCell}>
                {site.quantity}
              </TableCell>
              <TableCell className={classes.siteRowCell}>
                {formatMoney(site.salesExclVat)}
              </TableCell>
              <TableCell className={classes.siteRowCell}>
                {formatMoney(site.vat6)}
              </TableCell>
              <TableCell className={classes.siteRowCell}>
                {formatMoney(site.vat25)}
              </TableCell>
              <TableCell className={classes.siteRowCell}>
                {formatMoney(site.vatTotal)}
              </TableCell>
              <TableCell className={classes.siteRowCell}>
                {formatMoney(site.salesInclVat)}
              </TableCell>
            </TableRow>
            {site.paymentProviders.map((paymentProvider, index) => (
              <Fragment key={index}>
                <TableRow className={classes.paymentProviderRow}>
                  <TableCell />
                  <TableCell>{paymentProvider.name}</TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell>{paymentProvider.quantity}</TableCell>
                  <TableCell>
                    {formatMoney(paymentProvider.salesExclVat)}
                  </TableCell>
                  <TableCell>{formatMoney(paymentProvider.vat6)}</TableCell>
                  <TableCell>{formatMoney(paymentProvider.vat25)}</TableCell>
                  <TableCell>{formatMoney(paymentProvider.vatTotal)}</TableCell>
                  <TableCell>
                    {formatMoney(paymentProvider.salesInclVat)}
                  </TableCell>
                </TableRow>
                {paymentProvider.salesGroups.map((salesGroup, index) => (
                  <Fragment key={index}>
                    <TableRow key={index}>
                      <TableCell />
                      <TableCell />
                      <TableCell>{salesGroup.name}</TableCell>
                      <TableCell />
                      <TableCell>{salesGroup.quantity}</TableCell>
                      <TableCell>
                        {formatMoney(salesGroup.salesExclVat)}
                      </TableCell>
                      <TableCell>{formatMoney(salesGroup.vat6)}</TableCell>
                      <TableCell>{formatMoney(salesGroup.vat25)}</TableCell>
                      <TableCell>{formatMoney(salesGroup.vatTotal)}</TableCell>
                      <TableCell>
                        {formatMoney(salesGroup.salesInclVat)}
                      </TableCell>
                    </TableRow>
                    {salesGroup.regionGroups.map((regionGroup, index) => (
                      <TableRow key={index}>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell>{regionGroup.name}</TableCell>
                        <TableCell>{regionGroup.quantity}</TableCell>
                        <TableCell>
                          {formatMoney(regionGroup.salesExclVat)}
                        </TableCell>
                        <TableCell>{formatMoney(regionGroup.vat6)}</TableCell>
                        <TableCell>{formatMoney(regionGroup.vat25)}</TableCell>
                        <TableCell>
                          {formatMoney(regionGroup.vatTotal)}
                        </TableCell>
                        <TableCell>
                          {formatMoney(regionGroup.salesInclVat)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </Fragment>
                ))}
              </Fragment>
            ))}
          </Fragment>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={10} align="center">
            Inget rapportdata finns för denna filtrering. Prova ett annat
            datumintervall.
          </TableCell>
        </TableRow>
      )}
      {res?.sites.length > 0 && res.total && (
        <Fragment key="total">
          <TableRow className={classes.tableRowSum}>
            <TableCell colSpan={4} className={classes.siteRowCell}>
              Total
            </TableCell>
            <TableCell className={classes.siteRowCell}>
              {res.total.quantity}
            </TableCell>
            <TableCell className={classes.siteRowCell}>
              {formatMoney(res.total.salesExclVat)}
            </TableCell>
            <TableCell className={classes.siteRowCell}>
              {formatMoney(res.total.vat6)}
            </TableCell>
            <TableCell className={classes.siteRowCell}>
              {formatMoney(res.total.vat25)}
            </TableCell>
            <TableCell className={classes.siteRowCell}>
              {formatMoney(res.total.vatTotal)}
            </TableCell>
            <TableCell className={classes.siteRowCell}>
              {formatMoney(res.total.salesInclVat)}
            </TableCell>
          </TableRow>
          {res.total.paymentProviders.map((paymentProvider, index) => (
            <Fragment key={index}>
              <TableRow className={classes.tableRowSum}>
                <TableCell />
                <TableCell>{paymentProvider.name}</TableCell>
                <TableCell />
                <TableCell />
                <TableCell>{paymentProvider.quantity}</TableCell>
                <TableCell>
                  {formatMoney(paymentProvider.salesExclVat)}
                </TableCell>
                <TableCell>{formatMoney(paymentProvider.vat6)}</TableCell>
                <TableCell>{formatMoney(paymentProvider.vat25)}</TableCell>
                <TableCell>{formatMoney(paymentProvider.vatTotal)}</TableCell>
                <TableCell>
                  {formatMoney(paymentProvider.salesInclVat)}
                </TableCell>
              </TableRow>
            </Fragment>
          ))}
        </Fragment>
      )}
    </TableList>
  );
}

EconomySalesListResults.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  data: PropTypes.object,
  updatePage: PropTypes.func.isRequired,
  updateRowSize: PropTypes.func.isRequired,
  params: PropTypes.shape({
    month: PropTypes.string.isRequired,
  }).isRequired,
};

TransformDate.propTypes = {
  date: PropTypes.string.isRequired,
};
